import React from "react";
import { Button, Offcanvas, Form } from "react-bootstrap";
import Select from "react-select";


export default function AddNewUserDirectory(props) {
    const selectUserRoleOptions = [
        { value: '1', label: 'Admin' },
        { value: '2', label: 'Operations Reviewer' },
        { value: '3', label: 'Operations Approver' },
        { value: '4', label: 'Finanace Manager' },
    ];

    const selectClientOptions = [
        { value: '1', label: 'Microsoft' },
        { value: '2', label: 'Oracle' },
        { value: '3', label: 'IBM' },
        { value: '4', label: 'Salesforce' },
        { value: '5', label: 'Dell Technologies' },
        { value: '6', label: 'Splunk' },
        { value: '7', label: 'ServiceNow' },
        { value: '8', label: 'Elastic' },
    ];
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-30">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Add New User Directory</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="mb-4">
                        <Form.Label>User Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter User Name" />
                    </div>
                    <div className="mb-4">
                        <Form.Label>User Role</Form.Label>
                        <Select options={selectUserRoleOptions} isSearchable={true} />
                    </div>

                    <div className="mb-4">
                        <Form.Label>User Group</Form.Label>
                        <Select options={selectClientOptions} isSearchable={true} isMulti />
                    </div>

                    <div className="mb-4">
                        <Form.Label>User Status</Form.Label>
                        <Form.Select>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                        </Form.Select>
                    </div>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button type="submit" variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Add</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
};
import React, { useState, useEffect } from "react";
import { Button, Form, Offcanvas, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { format, subDays } from "date-fns";
import { AddDayWiseDataById, GetMappedContractTypeById, GetTimeSheetDayWiseBYId } from "../../../application/actions/timeSheetAction";
import ToastifyService from "../../_common/ToastifyService";
import { useDispatch, useSelector } from "react-redux";
import { getContractTypesById, getDayWiseListById } from "../../../application/selectors/indexSelector";
import { useNavigate } from "react-router-dom";
import { AddAppRoverTimeSheetStatus, AddTimeSheetStatus } from "../../../application/actions/approverTimeSheetAction";

export default function MonthsviewModal(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [gettimeSheetData, setGettimeSheetData] = useState(null);
    const [data, setData] = useState([]);
    const [editId, setEditId] = useState(null);
    const [forumla, setFormula] = useState(null);
    const [amount, setAmount] = useState(null);
    const [remarks, setRemarks] = useState("");

    const [editedRow, setEditedRow] = useState({});
    const [editedata, setEditedata] = useState({});

    const DayWiseTimeSheetList = useSelector(getDayWiseListById);
    const ContractTypes = useSelector(getContractTypesById);
    const [initialFetchData, setInitialFetchData] = useState(null);
  
    useEffect(() => {
      if (props.show) {
        if (props.data) {
          setGettimeSheetData(props.data);
          setData(props.data.months || "");
  
          const data = {
            Id: props?.data?.clientId,
            SubsId: props?.data?.subscriptionId,
            monthId: props?.data?.months,
          };
          setInitialFetchData(data);
          dispatch(GetTimeSheetDayWiseBYId(data));
          dispatch(GetMappedContractTypeById(data));
        }
      }
    }, [props.show, props.data]);
  
    useEffect(() => {
      if (props.show) {
        if (props.data) {
          switch (props?.data?.contractName) {
            case "Transaction Amount":
              setFormula("transactions");
              setAmount(ContractTypes?.transactionRate);
              break;
            case "FTE Amount":
              setFormula("transactions");
              setAmount(ContractTypes?.fteRate);
              break;
            case "Contingency Amount":
              setFormula("clientCollections");
              break;
            case "flatefee":
              setFormula("flatefee");
              break;
            default:
              setFormula(null);
          }
        }
      }
    }, [
      props.show,
      props.data,
      ContractTypes?.transactionRate,
      ContractTypes?.fteRate,
    ]);

    const handelSubmit = () => {
      const data = {
        OperationRemarks: remarks,
        Approver: 0,
        months: props?.data?.months,
        clientid: props?.data?.clientId,
        subscriptionid: props?.data?.subscriptionId,
      };
      dispatch(AddAppRoverTimeSheetStatus(data));
      ToastifyService.success("Operational Approver Accepted Timesheet Successfully Submitted!");
    };
  
    const handelReject = () => {
        const data = {
        OperationRemarks: remarks,
        Approver: 1,
        months: props?.data?.months,
        clientid: props?.data?.clientId,
        subscriptionid: props?.data?.subscriptionId,
      };
      dispatch(AddAppRoverTimeSheetStatus(data));
      ToastifyService.success("Operational Approver Rejected Timesheet Successfully Submitted!");
    };


    return (
      <React.Fragment>
      
        <Offcanvas
          show={props.show}
          onHide={props.closeFunction}
          placement="end"
          className="w-90"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="fs-16 text-dark">
            Approver TimeSheet
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            
            <Table className="mb-0" responsive>
              <thead>
                <tr>
                  <th>Day</th>
                  {/* Conditionally render headers based on contractName */}
                  {props?.data?.contractName === "Contingency Amount" ||
                    props?.data?.contractName === "flatefee" ? (
                    <th>Client Collections</th>
                  ) : null}
  
                  {props?.data?.contractName === "Transaction Amount" ||
                    props?.data?.contractName === "FTE Amount" ? (
                    <>
                      <th>Transactions</th>
                      <th>FTE Deployed</th>
                    </>
                  ) : null}
  
                  <th>{props?.data?.contractName}</th>
                  <th>Invoice Amount</th>
                </tr>
              </thead>
              <tbody>
                {DayWiseTimeSheetList.map((item, index) => {
                  const isWeekend =
                    item.daysWise === "Saturday" || item.daysWise === "Sunday";
  
                  return (
                    <tr
                      key={index}
                      style={isWeekend ? { backgroundColor: "#d3d3d3" } : {}}
                    >
                      <td>{item.daysWise}</td>
                      {props?.data?.contractName === "Transaction Amount" ||
                        props?.data?.contractName === "FTE Amount" ? (
                        <>
                          <td>
                            
                              <Form.Control
                                type="number"
                                value={item?.transactions || ""}
                              disabled
                              />
                            
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              value={
                                
                                   item?.fteDeployed
                              }
                              disabled
                            />
                          </td>
                        </>
                      ) : null}
  
                      {props?.data?.contractName === "Contingency Amount" ||
                        props?.data?.contractName === "flatefee" ? (
                        <td>
                          
                            <Form.Control
                              type="number"
                              value={item?.clientCollections || ""
                            }
                            disabled
                            />
                        
                        </td>
                      ) : null}
  
                      <td>
                        <Form.Control
                          type="text"
                          value={amount || "2"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          value={
                            
                               item?.invoiceAmount
                          }
                          disabled
                        />
                      </td>
                      
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th>Total</th>
                  {props?.data?.contractName === "Transaction Amount" ||
                    props?.data?.contractName === "FTE Amount" ? (
                    <>
                      <th>
                        {DayWiseTimeSheetList.reduce(
                          (acc, item) => acc + (item?.transactions || 0),
                          0
                        )}
                      </th>
                      <th>
                        {DayWiseTimeSheetList.reduce(
                          (acc, item) =>
                            acc + (Math.round(item?.fteDeployed) || 0),
                          0
                        )}
                      </th>
                    </>
                  ) : null}
  
                  {props?.data?.contractName === "Contingency Amount" ||
                    props?.data?.contractName === "flatefee" ? (
                    <th>
                      {DayWiseTimeSheetList.reduce(
                        (acc, item) =>
                          acc + (Math.round(item?.clientCollections) || 0),
                        0
                      )}
                    </th>
                  ) : null}
  
                  <th>
                    {DayWiseTimeSheetList.reduce(
                      (acc) => acc + (Math.round(amount) || 0),
                      0
                    )}
                  </th>
                  <th>
                    {DayWiseTimeSheetList.reduce(
                      (acc, item) => acc + (Math.round(item?.invoiceAmount) || 0),
                      0
                    )}
                  </th>
               
                </tr>
              </tfoot>
            </Table>
          </Offcanvas.Body>
  
          <div className="offcanvas-footer justify-content-between">
                    <div>
                        <Form.Control as="textarea" className="wt-500" placeholder="Remark.."  value={remarks}
          onChange={(e) => setRemarks(e.target.value)}/>
                    </div>
                    <div className="d-flex gap-2 ">
                         <Button type="submit" className="text-uppercase" variant="outline-danger"onClick={handelReject}>Reject</Button>
                        <Button type="submit" className="text-uppercase" variant="outline-primary" onClick={handelSubmit}>Approve</Button>
                    </div>
                </div>
        </Offcanvas>
      </React.Fragment>
    );
  }
  
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button, Form, Row, Col, Card, Table, Dropdown, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AddDayWiseDataById, AddTimeSheetStatus, GetMappedContractTypeById, GetTimeSheetDayWiseBYId } from "../../application/actions/timeSheetAction";
import { getContractTypesById, getDayWiseListById } from "../../application/selectors/indexSelector";
import ToastifyService from "../_common/ToastifyService";
import Projection from "./projection";
import { useNavigate } from "react-router-dom";
import Header from "../../layouts/Header";
import pdfIcon from "../../assets/img/icons/pdf.png";
import excelIcon from "../../assets/img/icons/excel.png";
import wordIcon from "../../assets/img/icons/word.png";

export default function MonthSheetModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [gettimeSheetData, setGettimeSheetData] = useState(null);
  const [data, setData] = useState([]);
  const [editId, setEditId] = useState(null);
  const [forumla, setFormula] = useState(null);
  const [amount, setAmount] = useState(null);
  const [editedRow, setEditedRow] = useState({});
  const [editedata, setEditedata] = useState({});
  const [showProjection, setShowProjection] = useState(false);
  const [projectionData, setProjectionData] = useState(null);
  const DayWiseTimeSheetList = useSelector(getDayWiseListById);
  const ContractTypes = useSelector(getContractTypesById);
  const [initialFetchData, setInitialFetchData] = useState(null);

  const location = useLocation();
  const rowData = location.state.row || {};

  useEffect(() => {
    if (rowData) {
      setGettimeSheetData(rowData);
      setData(rowData?.months || "")
      const data = {
        Id: rowData?.clientId,
        SubsId: rowData?.subscriptionId,
        monthId: rowData?.months,
      };
      setInitialFetchData(data);
      dispatch(GetTimeSheetDayWiseBYId(data));
      dispatch(GetMappedContractTypeById(data));
    }
  }, [rowData]);
  console.log("ContractTypes", ContractTypes);

  useEffect(() => {
    if (rowData) {
      switch (rowData?.contractName) {
        case "Transaction Amount":
          setFormula("transactions");
          setAmount(ContractTypes?.transactionRate);
          break;
        case "FTE Amount":
          setFormula("transactions");
          setAmount(ContractTypes?.fteRate);
          break;
        case "Contingency Amount":
          setFormula("clientCollections");
          break;
        case "flatefee":
          setFormula("flatefee");
          break;
        default:
          setFormula(null);
      }
    }

  }, [
    rowData,
    ContractTypes?.transactionRate,
    ContractTypes?.fteRate,
  ]);

  // HANDLE PROJECTION FUNCTION
  const handleProjectionClick = () => {
    setShowProjection(true);
    setProjectionData(rowData);
    console.log("Get Projection Data...", rowData);
  };

  // HANDLE EDIT FUNCTION
  const handleEditClick = (row) => {
    setEditId(row.id);

    console.log("editId", row);
    setEditedRow({ ...row });
  };

  // HANDLE SAVE FUNCTION
  const handleSaveClick = async () => {
    const data1 = {
      id: editedRow.id,
      transactions: editedRow.transactions || 0,
      ClientCollections: editedRow.ClientCollections || 0,
      FTEDeployed: editedata.FTEDeployed || 0,
      TransactionAmount: editedata.TransactionAmount || 0,
      FTEAmount: editedata.FTEAmount || 0,
      ContingencyAmount: editedata.ContingencyAmount || 0,
      InvoiceAmount: editedata.invoiceAmount || 0,
    };

    try {
      dispatch(AddDayWiseDataById({ data1, initialFetchData }));
      setEditId(null);
      ToastifyService.success("Updated Timesheet!");
    } catch (error) {
      console.error("Error saving data:", error);
      ToastifyService.error("Failed to update Timesheet!");
    }
  };

  // HANDLE INPUT CHANGE FUNCTION
  const handleInputChange = (e, field) => {
    const value = e.target.value;

    setEditedRow((prevState) => {
      const updatedRow = { ...prevState, [field]: value };
      let invoiceAmount = updatedRow?.invoiceAmount;
      if (field === "transactions" && forumla === "transactions") {
        if (rowData?.contractName === "Transaction Amount") {
          invoiceAmount = value * (2 || 1);
          setEditedata({ invoiceAmount: invoiceAmount });
        } else if (rowData?.contractName === "FTE Amount") {
          const fteDeployed = value / (ContractTypes?.transactionTarget || 1);
          invoiceAmount = fteDeployed * (amount || 1);
          updatedRow.fteDeployed = fteDeployed;
          setEditedata({
            FTEDeployed: fteDeployed,
            invoiceAmount: invoiceAmount,
            FTEAmount: amount,
          });
        }
      } else if (
        field === "clientCollections" &&
        forumla === "clientCollections"
      ) {
        invoiceAmount = value;
        setEditedata({ invoiceAmount: invoiceAmount });
      } else if (forumla === "flatefee") {
        invoiceAmount = updatedRow.amount;
        setEditedata({ invoiceAmount: invoiceAmount });
      }
      updatedRow.invoiceAmount = invoiceAmount;
      return updatedRow;
    });
  };

  // HANDLE SUBMIT FUCTION
  const handelSubmit = () => {
    const data = {
      clientid: rowData?.clientId,
      subscriptionid: rowData?.subscriptionId,
      months: rowData?.months,
    };
    dispatch(AddTimeSheetStatus(data));
    ToastifyService.success("operational reviewer Timesheet Successfully Submitted!"); 
  };

  return (
    <React.Fragment>

      <Header />
      <Projection show={showProjection} closeFunction={() => setShowProjection(false)} data={projectionData} />
      <div className="main main-app manage-item-page p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/timesheet/manage">Timesheet</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Timesheet View</li>
            </ol>
            <h4 className="main-title mb-0">{`${rowData?.months}`}</h4>
          </div>
          <div className="d-flex gap-2">
            <Button variant="dark" onClick={handleProjectionClick}>Projection</Button>
            <Dropdown>
              <Dropdown.Toggle variant="outline-primary" className="btn-icon custom-dropdown-action wt-35 ht-35">
                <i className="ri-more-2-fill fs-18"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#"><i className="ri-file-copy-line"></i> Upload Document</Dropdown.Item>
                <Dropdown.Item href="#"><i className="ri-file-copy-line"></i> View Contract</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <Row>
          {/* TIMESHEET TABLE VIEW */}
          <Col md={8}>
            <Card className="card-one timesheet-view-table">
              <Card.Body className="overflow-y">
                <Table className="mb-0" responsive>
                  <thead>
                    <tr>
                      <th>Day</th>
                      {/* Conditionally render headers based on contractName */}
                      {rowData?.contractName === "Contingency Amount" ||
                        rowData?.contractName === "flatefee" ? (
                        <th>Client Collections</th>
                      ) : null}

                      {rowData?.contractName === "Transaction Amount" ||
                        rowData?.contractName === "FTE Amount" ? (
                        <>
                          <th>Transactions</th>
                          <th>FTE Deployed</th>
                        </>
                      ) : null}

                      <th>{rowData?.contractName}</th>
                      <th>Invoice Amount</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {DayWiseTimeSheetList.map((item, index) => {
                      const isWeekend =
                        item.daysWise === "Saturday" || item.daysWise === "Sunday";

                      return (
                        <tr
                          key={index}
                          style={isWeekend ? { backgroundColor: "#d3d3d3" } : {}}
                        >
                          <td>{item.daysWise}</td>
                          {rowData?.contractName === "Transaction Amount" ||
                            rowData?.contractName === "FTE Amount" ? (
                            <>
                              <td>
                                {item.id === editId && !isWeekend ? (
                                  <Form.Control
                                    type="number"
                                    value={editedRow?.transactions || ""}
                                    onChange={(e) =>
                                      handleInputChange(e, "transactions")
                                    }
                                  />
                                ) : (
                                  item.transactions
                                )}
                              </td>
                              <td>
                                <Form.Control
                                  type="number"
                                  value={
                                    item.id === editId
                                      ? editedRow?.fteDeployed
                                      : item?.fteDeployed
                                  }
                                  disabled
                                />
                              </td>
                            </>
                          ) : null}

                          {rowData?.contractName === "Contingency Amount" ||
                            rowData?.contractName === "flatefee" ? (
                            <td>
                              {item.id === editId && !isWeekend ? (
                                <Form.Control
                                  type="number"
                                  value={editedRow?.clientCollections || ""}
                                  onChange={(e) =>
                                    handleInputChange(e, "clientCollections")
                                  }
                                />
                              ) : (
                                item.clientCollections
                              )}
                            </td>
                          ) : null}

                          <td>
                            <Form.Control
                              type="text"
                              value={amount || "2"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              value={
                                item.id === editId
                                  ? editedRow?.invoiceAmount
                                  : item?.invoiceAmount
                              }
                              disabled
                            />
                          </td>
                          <td>
                            {item.id === editId ? (
                              <Button
                                variant="success"
                                onClick={handleSaveClick}
                                disabled={isWeekend}
                              >
                                Save
                              </Button>
                            ) : (
                              <Button
                                variant="primary"
                                className="btn-icon"
                                onClick={() => handleEditClick(item)}
                                disabled={isWeekend}
                              >
                                <i className="ri-pencil-fill"></i>
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th className="bg-gray-200">Total</th>
                      {rowData?.contractName === "Transaction Amount" ||
                        rowData?.contractName === "FTE Amount" ? (
                        <>
                          <th className="bg-gray-200">
                            {DayWiseTimeSheetList.reduce(
                              (acc, item) => acc + (item?.transactions || 0),
                              0
                            )}
                          </th>
                          <th className="bg-gray-200">
                            {DayWiseTimeSheetList.reduce(
                              (acc, item) =>
                                acc + (Math.round(item?.fteDeployed) || 0),
                              0
                            )}
                          </th>
                        </>
                      ) : null}

                      {rowData?.contractName === "Contingency Amount" ||
                        rowData?.contractName === "flatefee" ? (
                        <th className="bg-gray-200">
                          {DayWiseTimeSheetList.reduce(
                            (acc, item) =>
                              acc + (Math.round(item?.clientCollections) || 0),
                            0
                          )}
                        </th>
                      ) : null}

                      <th className="bg-gray-200">
                        {DayWiseTimeSheetList.reduce(
                          (acc) => acc + (Math.round(amount) || 0),
                          0
                        )}
                      </th>
                      <th className="bg-gray-200">
                        {DayWiseTimeSheetList.reduce(
                          (acc, item) => acc + (Math.round(item?.invoiceAmount) || 0),
                          0
                        )}
                      </th>
                      <th className="bg-gray-200"></th>
                    </tr>
                  </tfoot>
                </Table>
              </Card.Body>

              <Card.Footer>
                <Button type="submit" variant="primary" className="fs-14 d-flex align-items-center" onClick={handelSubmit} >
                  <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                  <span className="align-middle">Submit</span>
                </Button>
              </Card.Footer>
            </Card>
          </Col>

          {/* UPLOADED DOCUMENT FILE */}
          <Col md={4}>
            <Card className="card-one h-auto mb-3">
              <Card.Header>Uploaded Document</Card.Header>
              <Card.Body>
                <Table>
                  <tbody>
                    {[
                      {
                        icon: pdfIcon,
                        name: "PDF File 2023",
                      },

                      {
                        icon: excelIcon,
                        name: "Excel File 2023",
                      },

                      {
                        icon: wordIcon,
                        name: "Word File 2023",
                      },
                    ].map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div>
                            {/* Use item.icon instead of item.pdfIcon */}
                            <Image src={item.icon} alt="icon" />
                            <span className="ms-2">{item.name}</span>
                          </div>
                        </td>
                        <td className="w-0">
                          <Button variant="dark" className="btn-icon">
                            <i className="ri-eye-line"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <Card className="card-one h-auto">
              <Card.Header>Contract Document</Card.Header>
              <Card.Body>
              <Table>
                  <tbody>
                    {[
                      {
                        icon: pdfIcon,
                        name: "PDF File 2023",
                      },

                      {
                        icon: pdfIcon,
                        name: "PDF File 2023",
                      },

                      {
                        icon: pdfIcon,
                        name: "PDF File 2023",
                      },
                    ].map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div>
                            {/* Use item.icon instead of item.pdfIcon */}
                            <Image src={item.icon} alt="icon" />
                            <span className="ms-2">{item.name}</span>
                          </div>
                        </td>
                        <td className="w-0">
                          <Button variant="dark" className="btn-icon">
                            <i className="ri-eye-line"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
export const GET_ALL_CLIENT = "GET_ALL_CLIENT";
export const GET_ALL_CLIENT_SUCCESS = "GET_ALL_CLIENT_SUCCESS";
export const GET_ALL_CLIENT_MASTER = "GET_ALL_CLIENT_MASTER";
export const GET_ALL_CLIENT_MASTER_SUCCESS = "GET_ALL_CLIENT_MASTER_SUCCESS";
export const GET_ALL_CLIENT_MASTER_BY_ID = "GET_ALL_CLIENT_MASTER_BY_ID";
export const GET_ALL_CLIENT_MASTER_BY_ID_SUCCESS = "GET_ALL_CLIENT_MASTER_BY_ID_SUCCESS";
export const Add_Master_CLIENTMASTER = "Add_Master_CLIENTMASTER";

export const GetClientList = {
  type: GET_ALL_CLIENT
};

export const GetAllClientSuccess = (data) => ({
  type: GET_ALL_CLIENT_SUCCESS,
  payload: data
});
export const GetClientMasterList = {
  type: GET_ALL_CLIENT_MASTER
};

export const GetAllClientMasterSuccess = (data) => ({
  type: GET_ALL_CLIENT_MASTER_SUCCESS,
  payload: data
});
export const GetClientMasterById = (data) => ({
  type: GET_ALL_CLIENT_MASTER_BY_ID,
  payload: data
});

export const GetAllClientMasterByIdSuccess = (data) => ({
  type: GET_ALL_CLIENT_MASTER_BY_ID_SUCCESS,
  payload: data
});
export const AddMastercli = (data) => ({  
  type: Add_Master_CLIENTMASTER,
  payload:data
});
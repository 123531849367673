import React, { useState } from "react";
import { Badge, Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import userGroupData from "../../../../Json/Reviewer/user-setip/user-group.json";
import AddNewUserGroup from "./add-new-user";

export default function UserGroups() {
  const [showAddNewUser, setShowAddNewUser] = useState(false);

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
    },
    {
      name: "Group Name",
      selector: (row) => row.group_name,
    },
    {
      name: "User Count",
      selector: (row) => (<Badge bg="primary" pill>{row.user_count}</Badge>),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button variant="secondary" className="btn-icon">
            <i className="ri-eye-line"></i>
          </Button>
        </div>
      ),
    },
  ];

  const handleAddUser = (state)=> {
    setShowAddNewUser(state);
  };

  return (
    <React.Fragment>
      <AddNewUserGroup show={showAddNewUser} closeFunction={handleAddUser} />

      <div className="d-md-flex align-items-center justify-content-between mb-3">
        <div>
          <h4 className="main-title fs-14 mb-0">List of User Group</h4>
        </div>
        <div>
          <Button variant="primary" className="d-flex align-items-center gap-2" onClick={()=> handleAddUser(true)}>
            <i className="ri-add-line fs-18 lh-1"></i>
            <span className="d-sm-inline"> Add New User Group</span>
          </Button>
        </div>
      </div>
      <Card className="card-one">
        <Card.Body>
          <div className="custom-common-table">
            <DataTable
              columns={columns}
              data={userGroupData}
              fixedHeader
              search={true}
              highlightOnHover
              pagination
            ></DataTable>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}
import { Add_Master_CLIENTMASTER, GET_ALL_CLIENT,  GET_ALL_CLIENT_MASTER,  GET_ALL_CLIENT_MASTER_BY_ID,  GetAllClientMasterByIdSuccess,  GetAllClientMasterSuccess,  GetAllClientSuccess } from "../actions/clientAction";

const getData =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
    if (action.type === GET_ALL_CLIENT) {
      try {
      const getclientdata = await api.clientAPI.GetAllClientsList();
      dispatch(GetAllClientSuccess(getclientdata));
    }catch (error) {
      if (error.response.data === "Invalid access token or refresh token") {
        localStorage.removeItem("Token");
      }
      }
    }
    next(action);
  };
const getMasterData =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
    if (action.type === GET_ALL_CLIENT_MASTER) {
      try {
      const getclientmasterdata = await api.clientAPI.GetAllClientsMasterList();
      dispatch(GetAllClientMasterSuccess(getclientmasterdata));
    }catch (error) {
      if (error.response.data === "Invalid access token or refresh token") {
        localStorage.removeItem("Token");
      }
      }
    }
    next(action);
  };
const getMasterDataById =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
    if (action.type === GET_ALL_CLIENT_MASTER_BY_ID) {
      try {       
      const getclientmasterdataById = await api.clientAPI.GetAllClientsMasterById(action.payload);
      dispatch(GetAllClientMasterByIdSuccess(getclientmasterdataById));
    }catch (error) {
      if (error.response.data === "Invalid access token or refresh token") {
        localStorage.removeItem("Token");
      }
      }
    }
    next(action);
  };

  const Add_Mater_Clients =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
      try {
    if (action.type === Add_Master_CLIENTMASTER) {
      const AddMasterdata = await api.clientAPI.AddMaterClients(action.payload);
      const getclientmasterdata = await api.clientAPI.GetAllClientsMasterList();
      dispatch(GetAllClientMasterSuccess(getclientmasterdata));
    }
  }
  catch (error) {
    if (error.response.data === "Invalid access token or refresh token") {
      localStorage.removeItem("Token");
    }
    }
    next(action);
  };
export default [getData,getMasterData,getMasterDataById,Add_Mater_Clients];

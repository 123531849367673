import React from "react";
import template1 from "../../assets/img/invoice-template/template-1.png";
import template2 from "../../assets/img/invoice-template/template-2.png";
import template3 from "../../assets/img/invoice-template/template-3.png";
import { Button } from "react-bootstrap";

export default function DefaultTemplates() {
    return (
        <React.Fragment>
            <div to="/invoicetemplate/manage/create" className="profile-template-view" style={{ backgroundImage: `url(${template1})` }}>
                <Button variant="dark" className="preview-bttn text-uppercase">Preview View</Button>
            </div>
            <div to="/invoicetemplate/manage/create" className="profile-template-view" style={{ backgroundImage: `url(${template2})` }}>
                <Button variant="dark" className="preview-bttn text-uppercase">Preview View</Button>
            </div>
            <div to="/invoicetemplate/manage/create" className="profile-template-view" style={{ backgroundImage: `url(${template3})` }}>
                <Button variant="dark" className="preview-bttn text-uppercase">Preview View</Button>
            </div>
        </React.Fragment>
    )
}
import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { useNavigate } from "react-router-dom";
import { Badge, Button, Card, Col, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Projection from "./projection";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllClientList,
  getClientSubscrtiption,
  getLoading,
  getMonthListById,
  getShowTimeSheetCreateModal,
  getTimeCount,
  getTimeDetailsList,
} from "../../application/selectors/indexSelector";
import { GetClientList } from "../../application/actions/clientAction";
import { GetClientSubscription } from "../../application/actions/subscriptionAction";
import CreateTimesheet from "./create-timesheet";
import {
  GetClientDetailsBYId,
  GetMonths,
  GetTimeSheetCount,
  GetTimeSheetDayWiseBYId,
  GetTimeSheetDetails,
  ShowCreateTimeSheetModal,
} from "../../application/actions/timeSheetAction";
import TableShimmerLoader from "../_common/ShimmerTable";
import MonthSheetModal from "./monthsheet";

export default function Timesheet() {
  const [timesheetData, setTimesheetData] = useState(null);
  const [weeklyData, setWeeklyData] = useState([]);
  const dispatch = useDispatch();
  const clientList = useSelector(getAllClientList);
  const SubsclientList = useSelector(getClientSubscrtiption);
  const [clientId, setClientId] = useState(null);
  const [subsId, setSubsId] = useState(null);
  const [monthId, setMonthsId] = useState(null);
  // const [TimeSheetLi, setTimeSheetList] = useState([]);
  const MonthWiseList = useSelector(getMonthListById);
  const CountList = useSelector(getTimeCount);
  const TimeSheetList = useSelector(getTimeDetailsList);
  const navigate = useNavigate();

  // const TimeSheetList1 = useSelector(getClientsListById);
  // useEffect(() => {
  //   if (TimeSheetList) {
  //     setTimeSheetList(TimeSheetList); // Set on load or TimeSheetList change
  //   }
  // }, [TimeSheetList]);

  // useEffect(() => {
  //   if (TimeSheetList1) {
  //     setTimeSheetList(TimeSheetList1); // Set when client list changes
  //   }
  // }, [TimeSheetList1]);

  useEffect(() => {
    dispatch(GetClientList);
    dispatch(GetTimeSheetCount);
    dispatch(GetTimeSheetDetails);
  }, []);

  const handleClientChange = (event) => {
    const selectedClientId = event?.target?.value;
    setClientId(selectedClientId); // Update clientId state
    dispatch(GetClientSubscription(selectedClientId)); // Fetch subscriptions based on client
  };

  const handleMonthsChange = (event) => {
    const MonthID = event.target.value;
    setMonthsId(MonthID); // Update clientId state
    // Fetch subscriptions based on client
  };

  const HandleSubmit = () => {
   
  };


  // Function to handle subscription change
  const handleSubsChange = async (event) => {
    const data = {
      Id: clientId, // Use the clientId from state
      SubsId: event?.target?.value, // Subscription ID from the event
    };
    setSubsId(event?.target?.value);
    try {
      // Dispatch or update MonthWiseList based on the subscription change
      dispatch(GetMonths(data)); // Assuming GetMonths is the Redux action
    } catch (error) {
      console.error("Error fetching months:", error);
    }
  };

  // const [showViewTimesheet, setShowViewTimesheet] = useState(false);
  // const handleViewTimesheet = (state) => {
  //   setShowViewTimesheet(state);
  // };

  const showCreateTimeSheetModalBool = useSelector(getShowTimeSheetCreateModal);
  const handleCreateTimesheet = (state) => {
    dispatch(ShowCreateTimeSheetModal(state));
  };


  // TABLE COLUMNS
  const columns = [
    {
      name: "Client/Subscription",
      selector: (row) => (
        <div>
          <h5 className="fs-14 mb-1">
            {row.clienName} - {row?.subsName}
          </h5>
        </div>
      ),
    },
    {
      name: "Months",
      selector: (row) => row?.months,
    },
    {
      name: "Timesheet Status",
      selector: (row) => (
        <Badge
          bg={
            row?.fieldValueTimeSheetStatus === "Pending" ? "warning" : "success"
          }
          pill
        >
          {row?.fieldValueTimeSheetStatus}
        </Badge>
      ),
    },

    {
      name: "Approver",
      selector: (row) => (
        <Badge
          bg={row?.fieldValueApprover === "Pending" ? "danger" : "success"}
          pill
        >
          {row?.fieldValueApprover}
        </Badge>
      ),
    },

    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex align-items-center gap-2">
          <Button
            variant="outline-dark"
            className="btn-icon"
            onClick={() => handleMonthSheet(row)}
          >
            <i className="ri-eye-line"></i>
          </Button>
        </div>
      ),
    },
  ];

  // HANDLE MONTHSHEET VIEW
  const handleMonthSheet = (row) => {
    setTimesheetData(row); // Update the state with the selected row data
    const data = {
      Id: row.clientId, // Ensure clientId is set
      SubsId: row.subscriptionId, // Ensure subsId is set
      monthId: row.months, // Ensure monthId is set
    };

    dispatch(GetTimeSheetDayWiseBYId(data));

    navigate('/timesheet/view', {
      state: {
        timesheetData,
        row,          
      },
    });
  };
  // const [showMonthSheet, setShowMonthSheet] = useState(false);
  // const handleMonthSheet = (rowData) => {
  //   setTimesheetData(rowData); // Set the row data
  //   setShowMonthSheet(true); // Show the UserDirectoryDetails component
  // };

  // Function to parse the day into a JavaScript Date object
  const parseDate = (dayString) => {
    return new Date(dayString);
  };

  // Function to get weekly data (Thursday to Thursday)
  const getWeeklyData = (monthData) => {
    let weeklyData = [];
    let currentWeek = [];

    monthData.forEach((dayData) => {
      const dayDate = parseDate(dayData.day);
      const dayOfWeek = dayDate?.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

      // Check if the current day is Thursday (dayOfWeek = 4)
      if (dayOfWeek === 4) {
        if (currentWeek?.length > 0) {
          weeklyData?.push(currentWeek);
          currentWeek = [];
        }
      }
      currentWeek.push(dayData);
    });

    if (currentWeek.length > 0) {
      weeklyData?.push(currentWeek); // Push the last week if it exists
    }

    return weeklyData;
  };

  const [projectionData, setProjectionData] = useState(null);
  const [showProjection, setShowProjection] = useState(false);
  const handleProjection = (rowData) => {
    const filteredWeeklyData = getWeeklyData(rowData.month_data); // Filter data for the selected row
    setProjectionData(rowData); // Set the row data
    setWeeklyData(filteredWeeklyData); // Set the filtered weekly data
    setShowProjection(true);
  };

  // Debugging to check if Count is being fetched correctly

  if (!CountList || CountList.totalTimeSheetCount === 0) {
    return <div>Loading...</div>; // Placeholder while data is being loaded or if no data
  }

  // Check for valid values or fallback to 0
  const totalTimeSheetCount = CountList[0]?.totalTimeSheetCount ?? 0;

  const totalApprovedCount = CountList[0]?.totalApprovedCount ?? 0;
  const totalPendingCount = CountList[0]?.totalPendingCount ?? 0;

  // Calculate percentages, handle division by zero
  const approvedPercent =
    totalTimeSheetCount > 0
      ? (totalApprovedCount / totalTimeSheetCount) * 100
      : 0;
  const pendingPercent =
    totalTimeSheetCount > 0
      ? (totalPendingCount / totalTimeSheetCount) * 100
      : 0;

  const cardData = [
    {
      label: "Total",
      icon: "ri-functions",
      value: totalTimeSheetCount, // Total count
      percent: "100", // Total is always 100%
      status: "up", // Assuming total doesn't have status change
    },
    {
      label: "Approved",
      icon: "ri-shield-check-line",
      value: totalApprovedCount, // Approved count
      percent: approvedPercent?.toFixed(2), // Approved percentage
      status: "up",
    },
    {
      label: "Pending",
      icon: "ri-time-line",
      value: totalPendingCount, // Pending count
      percent: pendingPercent?.toFixed(2), // Pending percentage
      status: "down",
    },
  ];

  return (
    <React.Fragment>
      <Header />
      <CreateTimesheet
        show={showCreateTimeSheetModalBool}
        closeFunction={handleCreateTimesheet}
      />

{/* <MonthSheetModal
  show={showMonthSheet}
  closeFunction={() => setShowMonthSheet(false)}
  data={timesheetData}
/> */}

      <Projection
        show={showProjection}
        closeFunction={() => setShowProjection(false)}
        data={projectionData}
        weeklyData={weeklyData}
      />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Timesheet</h4>
          </div>
          <div>
            <Button
              variant="primary"
              className="d-flex gap-1"
              onClick={() => handleCreateTimesheet(true)}
            >
              <i className="ri-add-line"></i>
              <span>Create New Timesheet</span>
            </Button>
          </div>
        </div>
        <Row className="g-3 mb-3">
          {cardData?.map((card, index) => (
            <Col key={index}>
              <Card className="card-one">
                <Card.Body>
                  <Card.Title as="label" className="fs-sm fw-medium mb-1">
                    {card.label}
                  </Card.Title>
                  <h3 className="card-value mb-1">
                    <i className={card?.icon}></i> {card?.value}
                  </h3>
                  <small>
                    <span
                      className={
                        "d-inline-flex text-" +
                        (card?.status === "up" ? "success" : "danger")
                      }
                    >
                      {card?.percent}%{" "}
                      <i
                        className={
                          "ri-arrow-" +
                          (card?.status === "up" ? "up" : "down") +
                          "-line"
                        }
                      ></i>
                    </span>
                  </small>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Card className="card-one">
          <Card.Header className="gap-3 justify-content-end">
            <div className="wt-300">
              <Form.Label>Client</Form.Label>
              <Form.Select onChange={handleClientChange}>
                <option>Choose Client</option>
                {clientList &&
                  clientList?.map((client) => (
                    <option key={client?.id} value={client?.id}>
                      {client?.clienName}
                    </option>
                  ))}
              </Form.Select>
            </div>
            <div className="wt-300">
              <Form.Label>Subscription</Form.Label>
              <Form.Select onChange={handleSubsChange}>
                <option>Choose Subscription</option>
                {SubsclientList &&
                  SubsclientList?.map((Subs) => (
                    <option key={Subs?.id} value={Subs?.id}>
                      {Subs?.subsName}
                    </option>
                  ))}
              </Form.Select>
            </div>
            <div className="wt-300">
              <Form.Label>Month</Form.Label>
              <Form.Select onChange={handleMonthsChange}>
                <option>Choose Month</option>
                {MonthWiseList &&
                  MonthWiseList?.map((mon) => (
                    <option
                      key={mon?.formatted_month}
                      value={mon?.formatted_month}
                    >
                      {mon?.formatted_month}
                    </option>
                  ))}
              </Form.Select>
            </div>

            <div>
              <Button
                type="Submit"
                variant="primary"
                className="d-flex align-items-center gap-2 mt-4"
                onClick={HandleSubmit}
              >
                <i className="ri-filter-line fs-18 lh-1"></i>
                <span className="d-sm-inline"> Filter</span>
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
              <DataTable
                columns={columns}
                data={TimeSheetList.result}
                fixedHeader
                highlightOnHover
                pagination
                paginationPerPage={10}
              />
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}

import React from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import userAvatar from "../assets/img/img1.jpg";
import { dashboardMenu, TimesheetMenu, ProjectionMenu, ApproverMenu, FinanaceManagerMenu, ManagementMenu, InvoiceMenu, InvoiceTemplateMenu, settingsMenu } from "../data/Menu";
import logo from "../assets/img/logo/main-logo.png";
import { Image } from "react-bootstrap";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
 
export default function Sidebar({ role }) {
 
  const axiosPrivate = useAxiosPrivate();
  const { setAuth, auth } = useAuth();
  const toggleFooterMenu = (e) => {
    e.preventDefault();
    let parent = e.target.closest(".sidebar");
    parent.classList.toggle("footer-menu-show");
  };
 
  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <Link to="/" className="sidebar-logo">
          <Image src={logo} className="wt-200 dark-mode-logo" />
        </Link>
      </div>
      <PerfectScrollbar className="sidebar-body">
        <SidebarMenu role={role} /> {/* Pass the role prop to SidebarMenu */}
      </PerfectScrollbar>
      <div className="sidebar-footer">
        <div className="sidebar-footer-top">
          <div className="sidebar-footer-thumb">
            <img src={userAvatar} alt="" />
          </div>
          <div className="sidebar-footer-body">
          <h5 className="mb-1 text-dark fw-semibold">{auth?.name}</h5>
          <p className="fs-sm text-secondary">{auth?.roles[0]}</p>
          </div>
          <Link onClick={toggleFooterMenu} to="" className="dropdown-link"><i className="ri-arrow-down-s-line"></i></Link>
        </div>
        <div className="sidebar-footer-menu">
          <nav className="nav">
            <Link to=""><i className="ri-edit-2-line"></i> Edit Profile</Link>
            <Link to=""><i className="ri-profile-line"></i> View Profile</Link>
          </nav>
          <hr />
          <nav className="nav">
            <Link to=""><i className="ri-question-line"></i> Help Center</Link>
            <Link to=""><i className="ri-lock-line"></i> Privacy Settings</Link>
            <Link to=""><i className="ri-user-settings-line"></i> Account Settings</Link>
            <Link to="/account/login"  onClick={() => {
                sessionStorage.clear();
                setAuth({});
              }
              }><i className="ri-logout-box-r-line"></i> Log Out</Link>
          </nav>
        </div>
      </div>
    </div>
  );
}
 
class SidebarMenu extends React.Component {
  populateMenu = (m) => {
    const menu = m.map((item, key) => {
      let sm;
      if (item.submenu) {
        sm = item.submenu.map((subItem, subKey) => (
          <NavLink to={subItem.link} className="nav-sub-link" key={subKey}>{subItem.label}</NavLink>
        ));
      }
 
      return (
        <li key={key} className="nav-item">
          {!sm ? (
            <NavLink to={item.link} className="nav-link"><i className={item.icon}></i> <span>{item.label}</span></NavLink>
          ) : (
            <div onClick={this.toggleSubMenu} className="nav-link has-sub"><i className={item.icon}></i> <span>{item.label}</span></div>
          )}
          {item.submenu && <nav className="nav nav-sub">{sm}</nav>}
        </li>
      );
    });
 
    return <ul className="nav nav-sidebar">{menu}</ul>;
  };
 
  // Toggle submenu visibility
  toggleSubMenu = (e) => {
    e.preventDefault();
    let parent = e.target.closest('.nav-item');
    let node = parent.parentNode.firstChild;
 
    while (node) {
      if (node !== parent && node.nodeType === Node.ELEMENT_NODE) node.classList.remove('show');
      node = node.nextElementSibling || node.nextSibling;
    }
 
    parent.classList.toggle('show');
    this.props.onUpdateSize();
  };
 
  render() {
    const { role } = this.props;
 
    return (
      <React.Fragment>
        {/* DASHBOARD MENU */}
           {role === "Admin" && (
        <div className="nav-group show without-dropdown">
          {this.populateMenu(dashboardMenu)}
        </div>
 )}
        {/* CONDITIONALLY RENDER TIMESHEET MENU FOR OPERATIONS REVIEWER */}
        {role === "Operations Reviewer" && (
          <div className="nav-group show without-dropdown">
            {this.populateMenu(TimesheetMenu)}
          </div>
        )}
 
        {/* CONDITIONALLY RENDER APPROVER MENU FOR FINANCE MANAGER */}
        {role === "Operations Approver" && (
          <div className="nav-group show without-dropdown">
            {this.populateMenu(ApproverMenu)}
          </div>
        )}
 
        {/* PROJECTION MENU */}
        <div className="nav-group show without-dropdown">
          {this.populateMenu(ProjectionMenu)}
        </div>
 
        {/* FINANCE MANAGER MENU */}
        {role === "Finanace Manager" && (
          <div className="nav-group show without-dropdown">
            {this.populateMenu(FinanaceManagerMenu)}
          </div>
        )}
 
        {/* MANAGEMENT MENU */}
        {role === "Finanace Manager" && (
        <div className="nav-group show without-dropdown">
          {this.populateMenu(ManagementMenu)}
        </div>
        )}
         {role === "Admin" && (
        <div className="nav-group show without-dropdown">
          {this.populateMenu(InvoiceMenu)}
        </div>
         )}
        {role === "Admin" && (
        <div className="nav-group show without-dropdown">
          {this.populateMenu(InvoiceTemplateMenu)}
        </div>
 )}
        {/* SYSTEM SETTINGS MENU */}
         {role === "Admin" && ( 
        <div className="nav-group show without-dropdown">
          {this.populateMenu(settingsMenu)}
        </div>
        )}
      </React.Fragment>
    );
  }
}
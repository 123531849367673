import { useRef, useState, useEffect, React } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Row, Col, Button, Form } from "react-bootstrap";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
const LOGIN_URL = "https://localhost:44306/api/Auth/login";
export default function LoginComponent() {
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const userRef = useRef();
    const errRef = useRef();
    const [user, setUser] = useState("");
    const [pwd, setPwd] = useState("");
    const [errMsg, setErrMsg] = useState("");
   
  
    useEffect(() => {
      userRef.current.focus();
    }, []);
  
    useEffect(() => {
      setErrMsg("");
    }, [user, pwd]);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
       
        const response = await axios.post(
          LOGIN_URL,
          JSON.stringify({ username: user, password: pwd }),
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        sessionStorage.setItem("auth", JSON.stringify(response?.data));
        const accessToken = response?.data?.accessToken;
        const refreshToken = response?.data?.refreshToken;
        const roles = response?.data?.roles;
        const id = response?.data?.id;
        const isResetDefaultPass = response?.data?.isResetDefaultPass;
        const name = response?.data?.name;
        setAuth({
          user,
          pwd,
          roles,
          accessToken,
          refreshToken,
          id,
          isResetDefaultPass,
          name,
        });
        setUser("");
        setPwd("");
        if (isResetDefaultPass) {
          navigate("/account/reset-password");
        } 
        else if(roles[0] === "Operations Reviewer" ) {
            navigate("/timesheet/manage");
          }
          else if ( roles[0] === "Operations Approver"){
            navigate("/approver/manage");
          }
          else if ( roles[0] === "Finanace Manager"){
            navigate("/finanaceManager/manage");
          }
          else{
            navigate("/dashboard/manage");
          }
        
        // navigate(from, { replace: true });
       
      } catch (err) {
        if (!err?.response) {
          setErrMsg("No Server Response");
        } else if (err.response?.status === 400) {
          setErrMsg("Missing Username or Password");
        } else if (err.response?.status === 401) {
          setErrMsg("Unauthorized");
        } else {
          setErrMsg("Login Failed");
        }
        errRef.current.focus();
        
      } finally {
       
      }
    };
  

   
    return (
        <div className="page-sign d-block py-0">
            <Row className="g-0">
                <Col md="7" lg="4" className="col-wrapper">
                    <Card className="card-sign">
                        <Card.Header>
                            <Card.Title>Login</Card.Title>
                            <Card.Text className="text-dark">Welcome back! Please login to continue.</Card.Text>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <div className="mb-4">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your Username"
                                        name="username"
                                        id="username"
                                        ref={userRef}
                                        autoComplete="off"
                                        onChange={(e) => setUser(e.target.value)}
                                        value={user}
                                        required
                                    />
                                </div>
                                <div className="mb-4">
                                    <Form.Label className="d-flex justify-content-between">Password</Form.Label>
                                    <Form.Control
                                        name="password"
                                        placeholder="Enter your password"
                                        type="password"
                                        id="password"
                                        onChange={(e) => setPwd(e.target.value)}
                                        value={pwd}
                                        required
                                    />
                                </div>
                                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                                <Button type="button" className="btn-sign" onClick={handleSubmit}>Let me in</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="d-none d-lg-block position-relative"></Col>
            </Row>
        </div>
    )
}
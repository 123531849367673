export const GET_TIME_SHEET_APPROVER_COUNT = "GET_TIME_SHEET_APPROVER_COUNT";
export const GET_TIME_SHEET_APPROVER_SUCCESS = "GET_TIME_SHEET_APPROVER_SUCCESS";
export const GET_APPROVER_TIME_SHEET_DETAILS = "GET_APPROVER_TIME_SHEET_DETAILS";
export const GET_APPROVER_TIME_SHEET_DETAILS_SUCCESS = "GET_APPROVER_TIME_SHEET_DETAILS_SUCCESS";
export const GET_APP_CLIENTS_List_BY_ID = "GET_APP_CLIENTS_List_BY_ID";
export const ADD_TIMESHEET_STATUS = "ADD_TIMESHEET_STATUS";
export const ADD_APPROVER_TIMESHEET_STATUS = "ADD_APPROVER_TIMESHEET_STATUS";

export const GetTimeSheetApproverCount = {
  type: GET_TIME_SHEET_APPROVER_COUNT,
};

export const GetTimeSheetApproverSuccess = (data) => ({
  type: GET_TIME_SHEET_APPROVER_SUCCESS,
  payload: data,
});
export const GetApproverTimeSheetDetails = {
  type: GET_APPROVER_TIME_SHEET_DETAILS,
};

export const GetApproverTimeSheetDetailsSuccess = (data) => ({
  type: GET_APPROVER_TIME_SHEET_DETAILS_SUCCESS,
  payload: data,
});


export const GetClientDetailsBYId = (data) => ({
  type: GET_APP_CLIENTS_List_BY_ID,
  payload: data,
});

export const AddAppRoverTimeSheetStatus = (data) => ({
  type: ADD_APPROVER_TIMESHEET_STATUS,
  payload: data,
});

import axios from "axios";
import Environment from "../../../core/Environment";

const GetCount = async () => {
  try {
    const response = await axios.get(`${Environment.Finance_Url}GetTimeSheetFinaceCount`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};
const GetFinanceCientList = async () => {
  try {
    const response = await axios.get(`${Environment.Finance_Url}GetTimeSheetFinaceMonthWise`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};
const GetClientById = async (data) => {
  try {
    const response = await axios.get(
      `${Environment.Finance_Url}GetTimeSheetFinaceMonthWiseBYClientId/${data.Id}/${data.SubsId}/${data.monthId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};
const AddFinanceTime_Sheet_Status = async (data) => {
  try {
    const response = await axios.post(
      `${Environment.Finance_Url}UpdateFinanceTimeSheetStatus`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  } finally{
    window.location.href="/finanaceManager/manage"; 
  }
};

export default {
  GetCount,GetFinanceCientList,GetClientById,AddFinanceTime_Sheet_Status
};

import axios from "axios";
import Environment from "../../../core/Environment";

const GetAllClientsList = async () => {
  try {
    const response = await axios.get(`${Environment.Client_Url}all-clients`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error; // Optionally re-throw to handle in the calling component
  }
};
const GetAllClientsMasterList = async () => {
  try {
    const response = await axios.get(`${Environment.Client_Url}GetmasterClientList`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error; // Optionally re-throw to handle in the calling component
  }
};
const GetAllClientsMasterById = async (Id) => {
  try {
    const response = await axios.get(`${Environment.Client_Url}GetmasterClient_By_Id/${Id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error; // Optionally re-throw to handle in the calling component
  }
};
const AddMaterClients = async (data) => {
  try {
    
    const response = await axios.post(`${Environment.Client_Url}AddMasterClient`, data);
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};

export default {
  GetAllClientsList,
  GetAllClientsMasterList,
  GetAllClientsMasterById,
  AddMaterClients
};

import React from "react";
import { Offcanvas, Button, Form } from "react-bootstrap";

export default function AddPaymentDetails(props) {
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-35">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Add Payment Detail</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <div className="mb-3">
                        <Form.Label>Ref#</Form.Label>
                        <Form.Control type="text" placeholder="Enter Ref#" />
                    </div>
                    <div className="mb-3">
                        <Form.Label>Paid Date</Form.Label>
                        <Form.Control type="date" placeholder="Enter Paid Date" />
                    </div>

                    <div className="mb-3">
                        <Form.Label>Paid Amount</Form.Label>
                        <Form.Control type="number" placeholder="Enter Paid Amout" />
                    </div>

                    <div className="mb-3">
                        <Form.Label>Adjustment Amount</Form.Label>
                        <Form.Control type="number" placeholder="Enter Adjustment Amount" />
                    </div>

                    <div className="mb-3">
                        <Form.Label>Remark</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Enter Remark" />
                    </div>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button type="submit" variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Submit</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
}
import React from "react";
import Header from "../../layouts/Header";
import { Link } from "react-router-dom";

import NewTemplate from "./TemplatesDesign/new-Template";
export default function CreateTemplate() {
    return (
        <React.Fragment>
            <Header />
            <div className="main main-app manage-item-page p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item">
                                <Link to="/invoicetemplate/manage">Invoice Template</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Create Template</li>
                        </ol>
                        <h4 className="main-title mb-0">Template</h4>
                    </div>
                </div>

                {/* TEMPLATES DESIGN */}
                <NewTemplate />
            </div>
        </React.Fragment>
    )
}
export const GET_TIME_SHEET_COUNT = "GET_TIME_SHEET_COUNT";
export const GET_TIME_SHEET_COUNT_SUCCESS = "GET_TIME_SHEET_COUNT_SUCCESS";
export const GET_TIME_SHEET_DETAILS = "GET_TIME_SHEET_DETAILS";
export const GET_TIME_SHEET_DETAILS_SUCCESS = "GET_TIME_SHEET_DETAILS_SUCCESS";
export const GET_MONTHS_List = "GET_MONTHS_List";
export const GET_MONTHS_SUCCESS = "GET_MONTHS_SUCCESS";
export const GET_CLIENTS_List_BY_ID = "GET_CLIENTS_List_BY_ID";
export const GET_CLIENT_BY_ID_SUCCESS = "GET_CLIENT_BY_ID_SUCCESS";
export const ADD_TIME_SHEET = "ADD_TIME_SHEET";
export const ADD_TIME_SHEET_SUCCESS = "ADD_TIME_SHEET_SUCCESS";
export const GET_TIME_SHEET_DAYWISE_BY_ID = "GET_TIME_SHEET_DAYWISE_BY_ID";
export const GET_TIME_SHEET_DAYWISE_BY_ID_SUCCESS =
  "GET_TIME_SHEET_DAYWISE_BY_ID_SUCCESS";
export const SHOW_CREATE_TIMESHEET_MODAL = "SHOW_CREATE_TIMESHEET_MODAL";
export const SHOW_CREATE_TIMESHEET_MODAL_SUCCESS =
  "SHOW_CREATE_TIMESHEET_MODAL_SUCCESS";
export const GET_PREDICATION_DAYWISE_BY_ID = "GET_PREDICATION_DAYWISE_BY_ID";
export const GET_PREDICATION_DAYWISE_BY_ID_SUCCESS =
  "GET_PREDICATION_DAYWISE_BY_ID_SUCCESS";
export const GET_MAPPED_CONTRACTYPE_BY_ID = "GET_MAPPED_CONTRACTYPE_BY_ID";
export const GET_MAPPED_CONTRACTYPE_BY_ID_SUCCESS =
  "GET_MAPPED_CONTRACTYPE_BY_ID_SUCCESS";
export const ADD_DAYWISE_DATA_BY_ID = "ADD_DAYWISE_DATA_BY_ID";
export const ADD_DAYWISE_PREDICATION_DATA_BY_ID =
  "ADD_DAYWISE_PREDICATION_DATA_BY_ID";
export const ADD_TIMESHEET_STATUS =
  "ADD_TIMESHEET_STATUS";

export const GetTimeSheetCount = {
  type: GET_TIME_SHEET_COUNT,
};

export const GetTimeSheetCountSuccess = (data) => ({
  type: GET_TIME_SHEET_COUNT_SUCCESS,
  payload: data,
});

export const GetTimeSheetDetails = {
  type: GET_TIME_SHEET_DETAILS,
};

export const GetTimeSheetDetailsSuccess = (data) => ({
  type: GET_TIME_SHEET_DETAILS_SUCCESS,
  payload: data,
});

export const GetMonths = (data) => ({
  type: GET_MONTHS_List,
  payload: data,
});

export const GetMonthsSuccess = (data) => ({
  type: GET_MONTHS_SUCCESS,
  payload: data,
});

export const GetClientDetailsBYId = (data) => ({
  type: GET_CLIENTS_List_BY_ID,
  payload: data,
});

export const GetClientSuccessList = (data) => ({
  type: GET_CLIENT_BY_ID_SUCCESS,
  payload: data,
});

export const AddTimeSheet = (data) => ({
  type: ADD_TIME_SHEET,
  payload: data,
});

export const AddTimeSheetSuccess = (data) => ({
  type: ADD_TIME_SHEET_SUCCESS,
  payload: data,
});

export const GetTimeSheetDayWiseBYId = (data) => ({
  type: GET_TIME_SHEET_DAYWISE_BY_ID,
  payload: data,
});

export const GetTimeSheetDayWiseSuccessList = (data) => ({
  type: GET_TIME_SHEET_DAYWISE_BY_ID_SUCCESS,
  payload: data,
});

export const GetPredictionDayWiseBYId = (data) => ({
  type: GET_PREDICATION_DAYWISE_BY_ID,
  payload: data,
});

export const GetPredictionDayWiseSuccessList = (data) => ({
  type: GET_PREDICATION_DAYWISE_BY_ID_SUCCESS,
  payload: data,
});

export const GetMappedContractTypeById = (data) => ({
  type: GET_MAPPED_CONTRACTYPE_BY_ID,
  payload: data,
});

export const GetMappedContractTypeByIdSuccessList = (data) => ({
  type: GET_MAPPED_CONTRACTYPE_BY_ID_SUCCESS,
  payload: data,
});

export const ShowCreateTimeSheetModal = (data) => ({
  type: SHOW_CREATE_TIMESHEET_MODAL,
  payload: data,
});

export const AddDayWiseDataById = (data) => ({
  type: ADD_DAYWISE_DATA_BY_ID,
  payload: data,
});
export const AddDayWisePredicationDataById = (data) => ({
  type: ADD_DAYWISE_PREDICATION_DATA_BY_ID,
  payload: data,
});

export const AddTimeSheetStatus = (data) => ({
  type: ADD_TIMESHEET_STATUS,
  payload: data,
});

import React, { useEffect, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllClientList,
  getClientSubscrtiption,
  getCommonListData,
  getTimeCount,
  getTimeDetailsList,
} from "../../application/selectors/indexSelector";
import { GetClientList } from "../../application/actions/clientAction";
import { GetClientSubscription } from "../../application/actions/subscriptionAction";
import { GetCommonList } from "../../application/actions/commonAction";
import {
  AddTimeSheet,
  GetTimeSheetCount,
  GetTimeSheetDetails,
} from "../../application/actions/timeSheetAction";

export default function CreateTimesheet(props) {
  const dispatch = useDispatch();
  const clientList = useSelector(getAllClientList);
  const Comomlist = useSelector(getCommonListData);
  localStorage.setItem("ContractTypelist", JSON.stringify(Comomlist));
  const SubsclientList = useSelector(getClientSubscrtiption);
  const TimeSheetList = useSelector(getTimeDetailsList);
  useEffect(() => {
    dispatch(GetClientList);
  }, []);

  const [clientId, setClientId] = useState(0);
  const [subscriptionId, setSubscriptionId] = useState(0);
  const [months, setMonths] = useState(new Date().toISOString().slice(0, 7));
  const [uid] = useState("fd43f296-3cc5-47b8-a077-621c05916d05");
  const [status] = useState("fd43f296-3cc5-47b8-a077-621c05916d05");
  const [approverId] = useState(5);
  const [timeSheetStatusId] = useState(7);

  useEffect(() => {
    if (props.show) {
      dispatch(GetCommonList);
    }
  }, [props.show]);

  const handleClientChange = (event) => {
    const clientId = event.target.value;
    setClientId(clientId);
    dispatch(GetClientSubscription(clientId));
  };

  const handleSubscriptionChange = (e) => {
    setSubscriptionId(e.target.value);
  };

  // For month input (YYYY-MM)
  const [fullDateTime, setFullDateTime] = useState(new Date().toISOString()); // Full ISO DateTime

  // Handle month change
  const handleMonthChange = (e) => {
    const selectedMonth = e.target.value; // YYYY-MM
    setMonths(selectedMonth);

    // Create a new Date object for the first day of the selected month
    const firstDayOfMonth = new Date(selectedMonth + "-01T00:00:00Z"); // Assuming UTC
    setFullDateTime(firstDayOfMonth.toISOString()); // Set full DateTime
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    const data = {
      months: fullDateTime,
      clientId: clientId, // Ensure it's an integer
      subscriptionId: subscriptionId, // Ensure it's an integer
      CreatedBy: "8dd409b7-21f8-4ac1-b64e-3d759d75c435",
      approverId: "8dd409b7-21f8-4ac1-b64e-3d759d75c435",
      timeSheetStatusId: "f58acecd-19ca-4075-a858-197dc2c42bdd",
      status: "5ed68f5c-db32-4841-8aa1-99b1f8721100",
    };

    try {
      dispatch(AddTimeSheet(data));
      // dispatch(GetTimeSheetDetails);
      // if (TimeSheetList?.statusCode === 202) {
      //   console.log("timesheetCreate", TimeSheetList?.statusCode);
      // } else {
      //   props.closeFunction();
      // }
    } catch (error) {
      console.error("Error creating timesheet:", error);
      // Optionally handle error display to user
    }
  };

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Create New Timesheet
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col md={8}>
              <div className="mb-4">
                <Form.Label>Client Name</Form.Label>
                <Form.Select onChange={handleClientChange}>
                  <option>Choose Client</option>
                  {clientList &&
                    clientList.map((client) => (
                      <option key={client.id} value={client.id}>
                        {client.clienName}
                      </option>
                    ))}
                </Form.Select>
              </div>
            </Col>
            <Col md={8}>
              <div className="mb-4">
                <Form.Label>Subscription</Form.Label>
                <Form.Select onChange={handleSubscriptionChange}>
                  <option>Choose Subscription</option>
                  {SubsclientList &&
                    SubsclientList.map((Subs) => (
                      <option key={Subs.id} value={Subs.id}>
                        {Subs.subsName}
                      </option>
                    ))}
                </Form.Select>
              </div>
            </Col>
            <Col md={8}>
              <div className="mb-4">
                <Form.Label>Month</Form.Label>
                <Form.Control
                  type="month"
                  value={months} // Use state value
                  onChange={handleMonthChange} // Update state on change
                />
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>
        <div className="offcanvas-footer justify-content-start">
          <Button
            type="submit"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            onClick={handleSubmit}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Create Timesheet</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}

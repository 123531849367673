import React from "react";
import { Badge, Card, Table } from "react-bootstrap";
import userRoleData from "../../../../Json/Reviewer/user-setip/user-role.json";

export default function UserRoles() {
    return (
        <React.Fragment>
            <div className="d-md-flex align-items-center justify-content-between mb-3">
                <div>
                    <h4 className="main-title fs-14 mb-0">List of User Role</h4>
                </div>
               
            </div>
           <Card className="card-one">
                <Card.Body>
                    <Table className="mb-0" responsive>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>User Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userRoleData.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.name}</td>
                                    <td><Badge bg="primary" pill>{item.user_count}</Badge></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}
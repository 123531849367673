import { GET_CLIENT_SUBSCRIPTION_SUCCESS, GET_MASTER_SUBSCRIPTION_SUCCESS, GET_SUBSCRIPTION_SUCCESS } from "../actions/subscriptionAction";

const initialState = {
    subcription: [],
    Clientsubcriptions: [],
    mastersubcriptions: [],
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_SUCCESS:
      return { ...state, subcription: action.payload.result, error: null };
    case GET_CLIENT_SUBSCRIPTION_SUCCESS:
      return { ...state, Clientsubcriptions: action.payload.result, error: null };
    case GET_MASTER_SUBSCRIPTION_SUCCESS:
      return { ...state, mastersubcriptions: action.payload.result, error: null };
    default:
      return state;
  }
};

export default reducer;

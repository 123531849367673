import React, { useState } from "react";
import { Badge, Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import userMasterData from "../../../../Json/Reviewer/user-setip/user-master.json";
import AddNewUserDirectory from "./add-user-directory";
import UserDirectoryDetails from "./user-directory-detail";

export default function UserDirectory() {
    const [userData, setUserData] = useState(null);
    const [showAddUserDirectory, setShowAddUserDirectory] = useState(false);
    const [showDirectoryDetails, setShowDirectoryDetails] = useState(false);

    const columns = [
        {
            name: "ID",
            selector: (row) => row.id,
        },
        {
            name: "User Name",
            selector: (row) => row.user_name,
        },
        {
            name: "User Role",
            selector: (row) => row.user_role,
        },
        {
            name: "User Group",
            selector: (row) => (
                <div className="d-flex flex-wrap gap-1 pt-2 pb-2">
                    {row.user_group.map((group, index) => (
                        <Badge key={index} bg="primary" pill className="me-1">
                            {group}
                        </Badge>
                    ))}
                </div>
            ),
        },
        {
            name: "Status",
            selector: (row) => (
                <Badge className={`${row.status === "Active" ? "bg-success" : ""} ${row.status === "Inactive" ? "bg-danger" : ""}`} pill>
                    {row.status}
                </Badge>
            ),
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex justify-content-end">
                    <Button
                        variant="secondary"
                        className="btn-icon"
                        onClick={() => handleDirectoryDetails(row)}>
                        <i className="ri-eye-line"></i>
                    </Button>
                </div>
            ),
        },
    ];

    const handleAddUserDirectory = (state) => {
        setShowAddUserDirectory(state);
    };

    const handleDirectoryDetails = (rowData) => {
        setUserData(rowData); // Set the row data
        setShowDirectoryDetails(true); // Show the UserDirectoryDetails component
    };

    return (
        <React.Fragment>
            <AddNewUserDirectory show={showAddUserDirectory} closeFunction={handleAddUserDirectory} />
            <UserDirectoryDetails show={showDirectoryDetails} closeFunction={() => setShowDirectoryDetails(false)} data={userData} />
            <div className="d-md-flex align-items-center justify-content-between mb-3">
                <div>
                    <h4 className="main-title fs-14 mb-0">List of User Directory</h4>
                </div>
                <div>
                    <Button variant="primary" className="d-flex align-items-center gap-2" onClick={() => handleAddUserDirectory(true)}>
                        <i className="ri-add-line fs-18 lh-1"></i>
                        <span className="d-sm-inline"> Add New User Directory</span>
                    </Button>
                </div>
            </div>
            <Card className="card-one">
                <Card.Body>
                    <DataTable
                        columns={columns}
                        data={userMasterData}
                        fixedHeader
                        search={true}
                        highlightOnHover
                        pagination
                    ></DataTable>
                </Card.Body>
            </Card>
        </React.Fragment>
    );
}

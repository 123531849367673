import React, { useState } from "react";
import { Badge, Card, Col, Form, Offcanvas, Row } from "react-bootstrap";
import "./client-details.scss"

export default function ClientDetails(props) {
    const [contractType, setContractType] = useState("FTE");
   // const [clientData, setClientData] = useState(null);

    const handleContractTypeChange = (e) => {
        const selectedType = e.target.value;
        setContractType(selectedType);
    };

    // useEffect(() => {
    //     if (props.data) {
    //         setClientData(props.data);
    //         console.log("Loaded Data:", props.data);
    //     }
    // }, [props.data]);

    return (
        <React.Fragment>

            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-90">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Client Details</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row className="g-3">
                        <Col xs={12} md={12} xl={9}>
                            <Card className="card-one mb-2 h-auto">
                                <Card.Body>
                                    <Row className="g-3 align-items-center flex-100 mt-3">
                                        <Col md={3} xs={6}>
                                            <div className="mb-2 product-wrapper h-auto">
                                                <h6 className="fs-12 text-secondary">Client Name</h6>
                                                <p className="fw-semibold fs-14 text-dark mb-0">Aamir Sayyed</p>
                                            </div>
                                        </Col>

                                        <Col md={3} xs={6}>
                                            <div className="mb-2 product-wrapper h-auto">
                                                <h6 className="fs-12 text-secondary">Client Code</h6>
                                                <p className="fw-semibold fs-14 text-dark mb-0">#25489</p>
                                            </div>
                                        </Col>
                                        <Col md={3} xs={6}>
                                            <div className="mb-2 product-wrapper h-auto">
                                                <h6 className="fs-12 text-secondary product-wrapper">Cost Center</h6>
                                                <p className="fw-semibold fs-14 text-dark mb-0">Sample Data</p>
                                            </div>
                                        </Col>

                                        <Col md={3} xs={6}>
                                            <div className="mb-2 product-wrapper h-auto">
                                                <h6 className="fs-12 text-secondary product-wrapper">Live Date</h6>
                                                <p className="fw-semibold fs-14 text-dark mb-0">18 August 2024</p>
                                            </div>
                                        </Col>

                                        <Col md={3} xs={6}>
                                            <div className="mb-2 product-wrapper h-auto">
                                                <h6 className="fs-12 text-secondary product-wrapper">Onboarding Date</h6>
                                                <p className="fw-semibold fs-14 text-dark mb-0">25 August 2024</p>
                                            </div>
                                        </Col>
                                        <Col md={3} xs={6}>
                                            <div className="mb-2 product-wrapper h-auto">
                                                <h6 className="fs-12 text-secondary product-wrapper">Contract Type</h6>
                                                <p className="fw-semibold fs-14 text-dark mb-0">FTE</p>
                                            </div>
                                        </Col>
                                        <Col md={3} xs={6}>
                                            <div className="mb-2 product-wrapper h-auto">
                                                <h6 className="fs-12 text-secondary product-wrapper mb-1">Subscription</h6>
                                                <Badge bg="success" className="fw-semibold fs-12" pill>Billable</Badge>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs={12} md={12} xl={3}>
                            <Card className="card-one h-auto">
                                <Card.Body>
                                    <div className="mb-4">
                                        <Form.Label>Subscription</Form.Label>
                                        <Form.Select>
                                            <option value="Billable">Billable</option>
                                            <option value="Non-Billable">Non-Billable</option>
                                        </Form.Select>
                                    </div>
                                    <div className="mb-4">
                                        <Form.Label>Contract Type</Form.Label>
                                        <Form.Select value={contractType} onChange={handleContractTypeChange}>
                                            <option value="FTE">FTE</option>
                                            <option value="Transaction">Transaction</option>
                                            <option value="Contingency">Contingency</option>
                                            <option value="Flat Fee">Flat Fee</option>
                                        </Form.Select>
                                    </div>

                                    <div>
                                        <h6 className="fs-14 fw-semibold text-dark mb-3">Pricing Model</h6>
                                        {contractType === "FTE" && (
                                            <>
                                                <div className="mb-3">
                                                    <Form.Label>FTE Approved</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter FTE Approved" />
                                                </div>
                                                <div className="mb-3">
                                                    <Form.Label>Transaction Target</Form.Label>
                                                    <div className="input-group">
                                                        <span className="input-group-text">$</span>
                                                        <Form.Control className="form-control" placeholder="Enter Transaction Target" type="number" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <Form.Label>FTE Rate</Form.Label>
                                                    <div className="input-group">
                                                        <span className="input-group-text">$</span>
                                                        <Form.Control className="form-control" placeholder="Enter FTE Rate" type="number" />
                                                    </div>
                                                </div>
                                            </>

                                        )}
                                        {contractType === "Transaction" && (
                                            <>
                                                <div className="mb-3">
                                                    <Form.Label>Transaction Count</Form.Label>
                                                    <div className="input-group">
                                                        <span className="input-group-text">$</span>
                                                        <Form.Control className="form-control" placeholder="Enter Transaction Count" type="number" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <Form.Label>Transaction Rate</Form.Label>
                                                    <div className="input-group">
                                                        <span className="input-group-text">$</span>
                                                        <Form.Control className="form-control" placeholder="Enter Transaction Rate" type="number" />
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {contractType === "Contingency" && (
                                            <div>
                                                <Form.Label>Percentage</Form.Label>
                                                <div className="input-group">
                                                    <span className="input-group-text">%</span>
                                                    <Form.Control className="form-control" placeholder="Enter Percentage" type="number" />
                                                </div>
                                            </div>
                                        )}

                                        {contractType === "Flat Fee" && (
                                            <div>
                                                <Form.Label>Fee Amount</Form.Label>
                                                <div className="input-group">
                                                    <span className="input-group-text">$</span>
                                                    <Form.Control className="form-control" placeholder="Enter Fee Amount" type="number" />
                                                </div>
                                            </div>
                                        )}
                                    </div>


                                    <div className="mt-4">
                                        <Form.Label>Operations Reviewers</Form.Label>
                                        <Form.Select>
                                            <option>Operations Reviewers</option>
                                            <option>Nirav Yadav</option>
                                            <option>Prachi Raut</option>
                                        </Form.Select>
                                    </div>
                                    <div className="mt-4">
                                        <Form.Label>Operations Approver</Form.Label>
                                        <Form.Select>
                                            <option>Choose Operations Approver</option>
                                            <option>Mahesh Kadam</option>
                                            <option>Umesh Chavan</option>
                                        </Form.Select>
                                    </div>
                                    <div className="mt-4">
                                        <Form.Label>Finanace Manager</Form.Label>
                                        <Form.Select>
                                            <option>Choose Finanace Manager</option>
                                            <option>Imran Qureshi</option>
                                            <option>Akshay Kadam</option>
                                        </Form.Select>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    )
}
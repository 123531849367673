import React from "react";
import { Button, Offcanvas, Form } from "react-bootstrap";
import Select from "react-select";


export default function MappedUserModal(props) {
    const selectClientOptions = [
        { value: '1', label: 'Microsoft' },
        { value: '2', label: 'Oracle' },
        { value: '3', label: 'IBM' },
        { value: '4', label: 'Salesforce' },
        { value: '5', label: 'Dell Technologies' },
        { value: '6', label: 'Splunk' },
        { value: '7', label: 'ServiceNow' },
        { value: '8', label: 'Elastic' },
    ];
    const selectUserOptions = [
        { value: '1', label: 'Aamir Sayyed' },
        { value: '2', label: 'Umesh Chavan' },
        { value: '3', label: 'Atik Shaikh' }
    ];
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-30">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Mapped User</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="mb-4">
                        <Form.Label>User Type</Form.Label>
                        <Form.Select>
                            <option disabled selected>Choose User Type</option>
                            <option>Admin</option>
                            <option>Finanace Reviewer</option>
                            <option>Operations Approver</option>
                            <option>Finanace Manager</option>
                        </Form.Select>
                    </div>

                    <div className="mb-4">
                        <Form.Label>User Group</Form.Label>
                        <Select options={selectClientOptions} isSearchable={true} isMulti />
                    </div>

                    <div>
                        <Form.Label>User Name</Form.Label>
                        <Select options={selectUserOptions} isSearchable={true} />
                    </div>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button type="submit" variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Submit</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
};
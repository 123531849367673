import React, { useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AddMastercli } from "../../../application/actions/clientAction";
import ToastifyService from "../../_common/ToastifyService";

export default function CreateClientMaster(props) {
    const dispatch = useDispatch();
    const [Code, setCode] = useState("");
    const [Name, setName] = useState("");
    const [Center, setCenter] = useState("");
    const [errors, setErrors] = useState({});

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Basic validation
        const newErrors = {};
        if (!Code) newErrors.Code = "Client Code is required";
        if (!Name) newErrors.Name = "Client Name is required";
        if (!Center) newErrors.Center = "Cost Center is required";

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        const data = {
            id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            status: "5ed68f5c-db32-4841-8aa1-99b1f8721100",
            createdBy: "26f35636-e033-4f42-b9d7-e2adeb050cc8", 
            createdOn: new Date().toISOString(),
            modifiedBy: "26f35636-e033-4f42-b9d7-e2adeb050cc8", 
            modifiedOn: new Date().toISOString(),
            clientCode: Code,
            clientName: Name,
            costCenter: Center,
        };

        try {
            dispatch(AddMastercli(data));
            props.closeFunction();
            setCode("");
            setName("");
            setCenter("");
            setErrors({});
            ToastifyService.success("Created client Masters!");
        } catch (error) {
            console.error("Error creating client master:", error);
        }
    };

    // Handlers for onChange events to clear errors for individual fields
    const handleCodeChange = (e) => {
        setCode(e.target.value);
        if (errors.Code) setErrors((prevErrors) => ({ ...prevErrors, Code: "" }));
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
        if (errors.Name) setErrors((prevErrors) => ({ ...prevErrors, Name: "" }));
    };

    const handleCenterChange = (e) => {
        setCenter(e.target.value);
        if (errors.Center) setErrors((prevErrors) => ({ ...prevErrors, Center: "" }));
    };

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Add New Client Master</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <Row>
                        <Col md={8}>
                            <div className="mb-4">
                                <Form.Label>Client Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Client Code"
                                    autoComplete="off"
                                    value={Code}
                                    onChange={handleCodeChange}
                                    isInvalid={!!errors.Code}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">{errors.Code}</Form.Control.Feedback>
                            </div>

                            <div className="mb-4">
                                <Form.Label>Client Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Client Name"
                                    autoComplete="off"
                                    value={Name}
                                    onChange={handleNameChange}
                                    isInvalid={!!errors.Name}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">{errors.Name}</Form.Control.Feedback>
                            </div>

                            <div>
                                <Form.Label>Cost Center</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Cost Center"
                                    autoComplete="off"
                                    value={Center}
                                    onChange={handleCenterChange}
                                    isInvalid={!!errors.Center}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">{errors.Center}</Form.Control.Feedback>
                            </div>
                        </Col>
                    </Row>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button
                        type="submit"
                        variant="primary"
                        className="fs-14 me-2 d-flex align-items-center"
                        onClick={handleSubmit}
                    >
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Create Client Master</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    );
}

import axios from "axios";
import Environment from "../../../core/Environment";

const GetCount = async () => {
  try {
    const response = await axios.get(
      `${Environment.TimeSheet_Url}GetTimeSheetCounts`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};
const GetTimeSheetDetails = async () => {
  try {
    const response = await axios.get(
      `${Environment.TimeSheet_Url}GetTimeSheetDetails`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};
const GetMonthWise = async (data) => {
  try {
    const response = await axios.get(
      `${Environment.TimeSheet_Url}GetMonths/${data.Id}/${data.SubsId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};
const GetClientById = async (data) => {
  try {
    const response = await axios.get(
      `${Environment.TimeSheet_Url}GetTimeSheetDetailsById/${data.Id}/${data.SubsId}/${data.monthId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};
const AddTime_Sheet = async (data) => {
  try {
    const response = await axios.post(
      `${Environment.TimeSheet_Url}AddTimeSheet`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};

const GetTimeSheetDayWiseById = async (data) => {
  try {
    const response = await axios.get(
      `${Environment.TimeSheet_Url}GetTimeSheetDayById/${data.Id}/${data.SubsId}/${data.monthId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};
const GetPredicationDayWiseById = async (data) => {
  try {
    const response = await axios.get(
      `${Environment.TimeSheet_Url}GetPredictiontDayById/${data.Id}/${data.SubsId}/${data.monthId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};
const GetMappedContractTypesById = async (data) => {  
  if (!data || !data.SubsId) {
    console.error("SubsId is required.");
    return;
  }

  const url = `${Environment.TimeSheet_Url}GetMappedContractTypesById/${data.SubsId}`;
  console.log("Request URL:", url);

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error.message);
    if (error.response) {
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
    }
    throw error;
  }
};


const Add_DayWise_By_Id = async (data) => {
  try {
    const response = await axios.post(
      `${Environment.TimeSheet_Url}InsertDataDaywiseTimeSheet`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};
const Add_DayWise_Pred_By_Id = async (data) => {
  try {
    const response = await axios.post(
      `${Environment.TimeSheet_Url}InsertDataDaywisePredication`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};
const AddTime_Sheet_Status = async (data) => {
  try {
    const response = await axios.post(
      `${Environment.TimeSheet_Url}UpdateTimeSheetStatus`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }finally{
    // window.location.reload(); 
    window.location.href="/timesheet/manage"; 
  }
};

export default {
  GetCount,
  GetTimeSheetDetails,
  GetMonthWise,
  GetClientById,
  AddTime_Sheet,
  GetTimeSheetDayWiseById,
  GetPredicationDayWiseById,
  GetMappedContractTypesById,
  Add_DayWise_By_Id,
  Add_DayWise_Pred_By_Id,
  AddTime_Sheet_Status,
};

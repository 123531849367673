export const getLoading = (state) => state.loader;


// client list
export const getAllClientList = (state) => state.client.GetAllClient;
export const getAllClientmasterList = (state) => state.client.GetAllClientMaster;
export const getAllClientmasterById = (state) => state.client.GetAllClientMasterById;

//common list
export const getCommonListData = (state)=> state.common.commonList;
export const getContractListData = (state)=> state.common.contractList;

// Subscription list
export const getSubscrtiptionData = (state)=> state.subscription.subcription;
export const getMasterSubscrtiptionData = (state)=> state.subscription.mastersubcriptions;
export const getClientSubscrtiption = (state)=> state.subscription.Clientsubcriptions;

// users 
export const getRolesData = (state)=> state.users.GetAllRoles;
export const getUserGroupData = (state)=> state.users.GetAllUserGroup;
export const getUserDirectoryData = (state)=> state.users.GetAllUserDirectory;

// TimeSheet 
export const getTimeCount = (state)=> state.timeSheet.coutList;
export const getTimeDetailsList = (state)=> state.timeSheet.getTimeSheetDetails;
export const getMonthListById = (state)=> state.timeSheet.monthsLi;
export const getClientsListById = (state)=> state.timeSheet.ClientListBYId;
export const getDayWiseListById = (state)=> state.timeSheet.DayWiseListBYId;
export const getPredictionDayWiseListById = (state)=> state.timeSheet.PredictionDayWiseListBYId;
export const getShowTimeSheetCreateModal = (state)=> state.timeSheet.showTimeSheetCreateModal;
export const getContractTypesById = (state)=> state.timeSheet.ContractTypesById;

// ApproverTimeSheet

export const getApproverTimeSheetCount =(state) => state.approver.coutList;
export const getApproverTimeDetailsList = (state)=> state.approver.getApproverTimeSheetDetails;


// FinanaceTimeSheet 
export const getFinanceTimeSheetCount =(state) => state.finance.coutList;
export const getFinanceTimeDetailsList = (state)=> state.finance.getFinanaceTimeSheetDetails;

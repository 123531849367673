import { GET_ALL_ROLES_SUCCESS, GET_ALL_USERDIRECTORY_SUCCESS, GET_ALL_USERGROUP_SUCCESS } from "../actions/usersAction";

const initialState = {
  GetAllRoles: [],
  GetAllUserGroup: [],
  GetAllUserDirectory: [],
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ROLES_SUCCESS:
      return { ...state, GetAllRoles: action.payload.result, error: null };
    case GET_ALL_USERGROUP_SUCCESS:
      return { ...state, GetAllUserGroup: action.payload.result, error: null };
    case GET_ALL_USERDIRECTORY_SUCCESS:
      return { ...state, GetAllUserDirectory: action.payload.result, error: null };
    default:
      return state;
  }
};

export default reducer;

import React, { useState } from "react";
import Header from "../../layouts/Header";
import { Nav, Tab } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import ClientSetup from "./ClientSetup/client-setup";
import UserSetup from "./UserSetup/user-setup";
import AccessLevel from "./AccessLevel/access-level";

export default function Reviewer() {
    const [isSidebarShow, setSidebarShow] = useState(false);

    return (
        <React.Fragment>
            <Header />

            <Tab.Container id="left-tabs-example" defaultActiveKey="client_setup_tab">
                <div className={"main main-file-manager" + (isSidebarShow ? " show" : "")}>
                    <PerfectScrollbar className="file-sidebar page-in-tabs">
                        <Nav variant="pills">
                            <Nav.Item><Nav.Link eventKey="client_setup_tab"><i className="ri-user-2-line"></i> Client setup</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="user_setup_tab"><i className="ri-group-line"></i> User setup</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="access_level_tab"><i className="ri-user-add-line"></i> Access Level</Nav.Link></Nav.Item>
                        </Nav>
                    </PerfectScrollbar>

                    <PerfectScrollbar className="file-content p-3 p-lg-4">
                        <Link href="" className="menu-file-manager" onClick={() => setSidebarShow(!isSidebarShow)}>
                            <i className="ri-arrow-left-line"></i>
                        </Link>
                        <Tab.Content>
                            {/* CLIENT SETUP TAB */}
                            <Tab.Pane eventKey="client_setup_tab"><ClientSetup /></Tab.Pane>

                            {/* USER SETUP TAB */}
                            <Tab.Pane eventKey="user_setup_tab"><UserSetup /></Tab.Pane>

                            {/* ACCESS LEVEL TAB */}
                            <Tab.Pane eventKey="access_level_tab"><AccessLevel /></Tab.Pane>
                        </Tab.Content>
                    </PerfectScrollbar>
                </div>
            </Tab.Container>
        </React.Fragment>
    )
};
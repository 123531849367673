import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SubcriptionsData from "../../../Json/Setting/Subcriptions.json";
import CreateSubscription from "./create-subscription";
import { useDispatch, useSelector } from "react-redux";
import { getSubscrtiptionData } from "../../../application/selectors/indexSelector";
import { GetSubscription } from "../../../application/actions/subscriptionAction";
import DetailSubscription from "./subscriptio-details";

export default function Subscription() {
  const dispatch = useDispatch();
  const SubscriptionList = useSelector(getSubscrtiptionData);
  useEffect(() => {
    dispatch(GetSubscription);
  }, []);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.subsName,
      sortable: true,
    },
    {
      name: "Contract Type",
      selector: (row) => row.contractName,
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row) => row.startDate,
      sortable: true,
    },
    {
      name: "Term Date",
      selector: (row) => row.endDate,
      sortable: true,
    },
    {
      name: "Billing Status",
      selector: (row) => (
        <Badge
          bg={row.billableStatus === "Non-Billable" ? "danger" : "success"}
          pill
        >
          {row.billableStatus}
        </Badge>
      ),
      sortable: true,
    },
    {
      name: "Monthly Minimum",
      selector: (row) => row.monthlyMinimum,
      sortable: true,
    },
    {
      name: "Operations Reviewer",
      selector: (row) => row.reviewer,
      sortable: true,
    },
    {
      name: "Operations Approver",
      selector: (row) => row.approver,
      sortable: true,
    },
    {
      name: "Finanace Manager",
      selector: (row) => row.manager,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => (
        <Badge bg={row.fieldValue === "InActive" ? "danger" : "success"} pill>
          {row.fieldValue}
        </Badge>
      ),
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) => row.createdByName,
      sortable: true,
    },
    {
      name: "View Pdf",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button
            variant="secondary"
            className="btn-icon">
            <i className="ri-eye-line"></i>{" "}
          </Button>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            className="btn-icon me-0"
            onClick={() => handleCreateSubscription(true)}
          >
            <i className="ri-pencil-line"></i>
          </Button>
        </div>
      ),
    },
  ];
  // SEARCH FILTER TABLE DATA
  const [searchTerm, setSearchTerm] = useState("");
  const filteredEmployees = SubcriptionsData?.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );
  const [showCreateSubscription, setShowCreateSubscription] = useState(false);
  const handleCreateSubscription = (state) => {
    setShowCreateSubscription(state);
  };

    // HANLDE DELETE SUBSCRIPTION
  

    return (
        <React.Fragment>
            <CreateSubscription show={showCreateSubscription} closeFunction={handleCreateSubscription} />
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="fs-14">Subscription</h4>
                </div>
                <div className="d-flex gap-2 mt-3 mt-md-0">
                    <Button variant="primary" className="d-flex align-items-center gap-2" onClick={() => handleCreateSubscription(true)}>
                        <i className="ri-add-line fs-18 lh-1"></i><span className="d-sm-inline">Create Subscription</span>
                    </Button>
                </div>
            </div>
            <Card className="card-one">
            <Card.Header className="align-items-center justify-content-between">
                    <h4 className="main-title fs-14 mb-0">List of Subscription</h4>
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-300">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                <DataTable
                            columns={columns}
                            data={SubscriptionList}
                            fixedHeader
                            search={true}
                            highlightOnHover
                            pagination
                        ></DataTable>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}

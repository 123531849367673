import axios from "axios";
import Environment from "../../../core/Environment";

const GetCount = async () => {
  try {
    const response = await axios.get(`${Environment.Approver_Url}GetTotalApproverCount`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};
const GetApproverCientList = async () => {
  try {
    const response = await axios.get(`${Environment.Approver_Url}GetTimeSheetApproverMonthWise`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};
const GetClientById = async (data) => {
  try {
    const response = await axios.get(
      `${Environment.Approver_Url}GetApproverTimeSheetDetailsById/${data.Id}/${data.SubsId}/${data.monthId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};

const AddAppRoverTime_Sheet_Status = async (data) => {
  try {
    const response = await axios.post(
      `${Environment.Approver_Url}UpdateApproverTimeSheetStatus`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  } finally{
    window.location.href="/approver/manage"; 
  }
};
export default {
  GetCount,GetApproverCientList,GetClientById,AddAppRoverTime_Sheet_Status
};

import React, { useState } from "react";
import unpaidInvoiceData from "../../Json/Invoice/unpaid-invoice.json";
import DataTable from "react-data-table-component";
import { Card, Form, Button } from "react-bootstrap";
import AddPaymentDetails from "./add-payment-modal";

export default function UnpaidInvoice() {
    const columns = [
        {
            name: "Client",
            selector: (row) => row?.client,
            sortable: true,
        },
        {
            name: "Sub",
            selector: (row) => row?.sub,
            sortable: true,
        },
        {
            name: "Month",
            selector: (row) => row?.month,
            sortable: true,
        },
        {
            name: "Invoice Type",
            selector: (row) => row?.invoice_type,
            sortable: true,
        },
        {
            name: "Invoice Amount Code",
            selector: (row) => row?.invoice_amount,
            sortable: true,
        },
        {
            name: "Submission Date",
            selector: (row) => row?.invoice_amount,
            sortable: true,
        },
        {
            name: "Add Payment Details",
            selector: (row) => (
                <div className="d-flex align-items-center gap-2">
                    <Button variant="outline-dark" onClick={()=> handleAddPaymentDetails(true)}>Add Payment Details</Button>
                </div>
            ),
            sortable: true,
        },
    ];

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredUnpaidInvoice = unpaidInvoiceData?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const [showAddPaymentDetails, setShowAddPaymentDetails] = useState(false);
    const handleAddPaymentDetails = (state)=> {
        setShowAddPaymentDetails(state)
    }

    return (
        <React.Fragment>
            <AddPaymentDetails show={showAddPaymentDetails} closeFunction={handleAddPaymentDetails} />
            <div className="d-md-flex align-items-center justify-content-between mb-3">
                <h3 className="fs-14 mb-1">Unpaid Invoice</h3>
            </div>

            <Card className="card-one">
                <Card.Header className="align-items-center justify-content-between">
                    <h4 className="main-title fs-14 mb-0">List of Unpaid Invoice</h4>
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-300">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <DataTable
                        columns={columns}
                        data={filteredUnpaidInvoice}
                        fixedHeader
                        search={true}
                        highlightOnHover
                        pagination
                    ></DataTable>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}
import clientmiddleware from './clientmiddleware';
import commonmiddleware from './commonmiddleware';
import subscriptionmiddleware from './subscriptionmiddleware';
import usersmiddleware from './usersmiddleware';
import timeSheetmiddleware from './timeSheetmiddleware';
import approvermiddleware from './approvermiddleware';
import financemiddleware from './financemiddleware';


export default [
    ...clientmiddleware,
    ...commonmiddleware,
    ...subscriptionmiddleware,
    ...usersmiddleware,
    ...timeSheetmiddleware,
    ...approvermiddleware,
    ...financemiddleware
]
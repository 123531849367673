import axios from "axios";
import Environment from "../../../core/Environment";

const GetAllUsersDirectoryList = async () => {
  try {
    const response = await axios.get(`${Environment.Users_Url}GetUserDirectory-List`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error; // Optionally re-throw to handle in the calling component
  }
};
const GetAllRolesList = async () => {
  try {
    const response = await axios.get(`${Environment.Client_Url}GetRolesList-WithCount`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error; // Optionally re-throw to handle in the calling component
  }
};
const GetAllUsersGroupList = async () => {
  try {
    const response = await axios.get(`${Environment.Client_Url}GetGroupList-WithCount`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error; // Optionally re-throw to handle in the calling component
  }
};
const AddUserDirectoryApi = async (data) => {
  try {
    const response = await axios.post(`${Environment.Users_Url}register`, data);
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};

export default {
    GetAllUsersDirectoryList,
    GetAllRolesList,
    GetAllUsersGroupList,
    AddUserDirectoryApi
};

import React, { useState, useEffect } from "react";
import { Button, Form, Offcanvas, Table } from "react-bootstrap";
// import {
//   format,
//   subDays,
//   startOfMonth,
//   endOfMonth,
//   addDays,
//   getDay,
// } from "date-fns";
import ToastifyService from "../_common/ToastifyService";
import { useDispatch, useSelector } from "react-redux";
import { getContractTypesById, getPredictionDayWiseListById } from "../../application/selectors/indexSelector";
import { AddDayWisePredicationDataById, GetMappedContractTypeById, GetPredictionDayWiseBYId } from "../../application/actions/timeSheetAction";

export default function Projection(props) {
  const dispatch = useDispatch();
  const [gettimeSheetData, setGettimeSheetData] = useState(null);
  const [data, setData] = useState([]);
  const [editId, setEditId] = useState(null);
  const [forumla, setFormula] = useState(null);
  const [amount, setAmount] = useState(null);
  const [editedRow, setEditedRow] = useState({});
  const [editedata, setEditedata] = useState({});
  const [contractName, setContractName] = useState("");
  const [clientId, setClientId] = useState(null);
  const [subsId, setSubsId] = useState(null);
  const [monthId, setMonthsId] = useState(null);
  const DayWiseTimeSheetList = useSelector(getPredictionDayWiseListById);
  const [initialFetchData, setInitialFetchData] = useState(null);
  const ContractTypes = useSelector(getContractTypesById);

  useEffect(() => {
    
    if (props.show) {
      if (props.data) {
        setGettimeSheetData(props.data);
        setData(props.data.months || "");
        const data = {
          Id: props?.data?.clientId,
          SubsId: props?.data?.subscriptionId,
          monthId: props?.data?.months,
        };
        setInitialFetchData(data);
        dispatch(GetPredictionDayWiseBYId(data));
        dispatch(GetMappedContractTypeById(data));
      }
    }
  }, [props.show, props.data]);

  
  useEffect(() => {
    if (props.show) {
      if (props.data) {
        switch (props?.data?.contractName) {
          case "Transaction Amount":
            setFormula("transactions");
            setAmount(ContractTypes?.transactionRate);
            break;
          case "FTE Amount":
            setFormula("transactions");
            setAmount(ContractTypes?.fteRate);
            break;
          case "Contingency Amount":
            setFormula("clientCollections");
            break;
          case "flatefee":
            setFormula("flatefee");
            break;
          default:
            setFormula(null);
        }
      }
    }
  }, [
    props.show,
    props.data,
    ContractTypes?.transactionRate,
    ContractTypes?.fteRate,
  ]);

  // HANDLE EDIT FUNCTION
  const handleEditClick = (row) => {
    setEditId(row.id);
    setEditedRow({ ...row });
  };

  // HANDLE SAVE FUNCTION
  const handleSaveClick = async () => {
    const data1 = {
      id: editedRow.id,
      transactions: editedRow.transactions || 0,
      ClientCollections: editedRow.ClientCollections || 0,
      FTEDeployed: editedata.FTEDeployed || 0,
      TransactionAmount: editedata.TransactionAmount || 0,
      FTEAmount: editedata.FTEAmount || 0,
      ContingencyAmount: editedata.ContingencyAmount || 0,
      InvoiceAmount: editedata.invoiceAmount || 0,
    };

    try {
      dispatch(AddDayWisePredicationDataById({ data1, initialFetchData }));
      setEditId(null);
      ToastifyService.success("Updated Timesheet!");
    } catch (error) {
      console.error("Error saving data:", error);
      ToastifyService.error("Failed to update Timesheet!");
    }
  };

  // HANDLE INPUT CHANGE FUNCTION
  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setEditedRow((prevState) => {
      const updatedRow = { ...prevState, [field]: value };
      let invoiceAmount = updatedRow?.invoiceAmount;
      if (field === "transactions" && forumla === "transactions") {
        if (props?.data?.contractName === "Transaction Amount") {
          invoiceAmount = value * (amount || 1);
          setEditedata({ InvoiceAmount: invoiceAmount });
        } else if (props?.data?.contractName === "FTE Amount") {
          const fteDeployed = value / (ContractTypes?.transactionTarget || 1);
          invoiceAmount = fteDeployed * (amount || 1);
          updatedRow.fteDeployed = fteDeployed;
          setEditedata({
            FTEDeployed: fteDeployed,
            invoiceAmount: invoiceAmount,
            FTEAmount: amount,
          });
        }
      } else if (
        field === "clientCollections" &&
        forumla === "clientCollections"
      ) {
        invoiceAmount = value;
        setEditedata({ InvoiceAmount: invoiceAmount });
      } else if (forumla === "flatefee") {
        invoiceAmount = updatedRow.amount;
        setEditedata({ InvoiceAmount: invoiceAmount });
      }
      updatedRow.invoiceAmount = invoiceAmount;
      return updatedRow;
    });
  };

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-90"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            projection
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <h5 className="fs-14 mb-3">
            Thursday in this Month:
            <span className="mb-5"> {props?.data?.months}</span>
          </h5>
          <Table className="mb-0" responsive>
            <thead>
              <tr>
                <th>Day</th>
                {props?.data?.contractName === "Contingency Amount" ||
                props?.data?.contractName === "flatefee" ? (
                  <th>Client Collections</th>
                ) : null}

                {props?.data?.contractName === "Transaction Amount" ||
                props?.data?.contractName === "FTE Amount" ? (
                  <>
                    <th>Transactions</th>
                    <th>FTE Deployed</th>
                  </>
                ) : null}

                <th>{props?.data?.contractName}</th>
                <th>Invoice Amount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {DayWiseTimeSheetList.map((item, index) => {
                const isWeekend =
                  item.daysWise === "Saturday" || item.daysWise === "Sunday";

                return (
                  <tr
                    key={index}
                    style={isWeekend ? { backgroundColor: "#d3d3d3" } : {}}
                  >
                    <td>{item.daysWise}</td>
                    {props?.data?.contractName === "Transaction Amount" ||
                    props?.data?.contractName === "FTE Amount" ? (
                      <>
                        <td>
                          {item.id === editId && !isWeekend ? (
                            <Form.Control
                              type="number"
                              value={editedRow?.transactions || ""}
                              onChange={(e) =>
                                handleInputChange(e, "transactions")
                              }
                            />
                          ) : (
                            item.transactions
                          )}
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={
                              item.id === editId
                                ? editedRow?.fteDeployed
                                : item?.fteDeployed
                            }
                            disabled
                          />
                        </td>
                      </>
                    ) : null}

                    {props?.data?.contractName === "Contingency Amount" ||
                    props?.data?.contractName === "flatefee" ? (
                      <td>
                        {item.id === editId && !isWeekend ? (
                          <Form.Control
                            type="number"
                            value={editedRow?.clientCollections || ""}
                            onChange={(e) =>
                              handleInputChange(e, "clientCollections")
                            }
                          />
                        ) : (
                          item.clientCollections
                        )}
                      </td>
                    ) : null}

                    <td>
                      <Form.Control
                        type="text"
                        value={amount || "2"}
                        disabled
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        value={
                          item.id === editId
                            ? editedRow?.invoiceAmount
                            : item?.invoiceAmount
                        }
                        disabled
                      />
                    </td>
                    <td>
                      {item.id === editId ? (
                        <Button
                          variant="success"
                          onClick={handleSaveClick}
                          disabled={isWeekend}
                        >
                          Save
                        </Button>
                      ) : (
                        <Button
                          variant="primary"
                          className="btn-icon"
                          onClick={() => handleEditClick(item)}
                          disabled={isWeekend}
                        >
                          <i className="ri-pencil-fill"></i>
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <th>Total</th>
                {props?.data?.contractName === "Transaction Amount" ||
                props?.data?.contractName === "FTE Amount" ? (
                  <>
                    <th>
                      {DayWiseTimeSheetList.reduce(
                        (acc, item) => acc + (item?.transactions || 0),
                        0
                      )}
                    </th>
                    <th>
                      {DayWiseTimeSheetList.reduce(
                        (acc, item) =>
                          acc + (Math.round(item?.fteDeployed) || 0),
                        0
                      )}
                    </th>
                  </>
                ) : null}

                {props?.data?.contractName === "Contingency Amount" ||
                props?.data?.contractName === "flatefee" ? (
                  <th>
                    {DayWiseTimeSheetList.reduce(
                      (acc, item) =>
                        acc + (Math.round(item?.clientCollections) || 0),
                      0
                    )}
                  </th>
                ) : null}

                <th>
                  {DayWiseTimeSheetList.reduce(
                    (acc) => acc + (Math.round(amount) || 0),
                    0
                  )}
                </th>
                <th>
                  {DayWiseTimeSheetList.reduce(
                    (acc, item) => acc + (Math.round(item?.invoiceAmount) || 0),
                    0
                  )}
                </th>
                <th></th>
              </tr>
            </tfoot>
          </Table>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
}

import React, { useState, useEffect } from "react";
import { Badge, Form, Offcanvas } from "react-bootstrap";

export default function UserDirectoryDetails(props) {
    const [directoryData, setDirectoryData] = useState(null);

    useEffect(() => {
        if (props.data) {
            setDirectoryData(props.data);
            console.log("Loaded Data:", props.data);
        }
    }, [props.data]);  // Added props.data as a dependency

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-30">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">User Directory Details</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {directoryData ? (

                        <div>
                            <div className="mb-4">
                                <Form.Label>User Name</Form.Label>
                                <div className="product-wrapper fs-14 p-2 fw-semibold">{directoryData.user_name}</div>
                            </div>
                            <div className="mb-4">
                                <Form.Label>User Role</Form.Label>
                                <div className="product-wrapper fs-14 p-2 fw-semibold">{directoryData.user_role}</div>
                            </div>

                            <div className="mb-4">
                                <Form.Label>User Group</Form.Label>
                                <div className="product-wrapper fs-14 p-2">
                                    {directoryData.user_group.map((group, index) => (
                                        <Badge key={index} bg="primary" pill className="me-1">{group}</Badge>
                                    ))}
                                </div>
                            </div>

                            <div className="mb-4">
                                <Form.Label>User Status</Form.Label>
                                <div className="product-wrapper fs-14 p-2 fw-semibold">
                                    <Badge className={`${directoryData.status === "Active" ? "bg-success" : ""}
                                    ${directoryData.status === "Inactive" ? "bg-danger" : ""}`} pill>{directoryData.status}</Badge>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p>No data available.</p>
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    );
}

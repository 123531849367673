// import axios from "axios";
// import Environment from "../Environment";

// const jwtInterceptor = axios.create();

// jwtInterceptor.interceptors.request.use((config) => {
//   const storedToken = JSON.parse(localStorage.getItem("Token"));
//   if (storedToken) {
//     config.headers.Authorization = `Bearer ${storedToken}`;
//   }

//   return config;
// });
// jwtInterceptor.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     debugger
//     if (error.response.status === 401) {
//       const storedToken = JSON.parse(localStorage.getItem("Token"));
//       const userProfile = JSON.parse(localStorage.getItem("userProfile"));
//       if (!storedToken) {
//         window.location.href = "/account/login";
//         throw new Error("No token available for refresh");
//       }
//       const id = window.location.href;
//       const domain = new URL(id).hostname;
//       debugger
//       const refreshResponse = await axios.post(`${Environment.Account_Url}refresh-token`,
//         {
//           accessToken: storedToken,
//           refreshToken: userProfile.refreshToken,
//           domain: domain === "localhost" ? "www.foodway.com":"19.168.2.76"?"www.foodway.com" : domain,
//         }
//         );
//         debugger
//       const responseToken = {
//         branchId: userProfile.branchId,
//         firstName: userProfile.firstName,
//         id: userProfile.id,
//         lastName: userProfile.lastName,
//         refreshToken: refreshResponse.data.refreshToken,
//         roleId: userProfile.roleId,
//         tenanatId: userProfile.tenanatId,
//         token: refreshResponse.data.accessToken,
//         username: userProfile.username
//       };
//       debugger
//         handelLocal(responseToken)
//       // Retry the original request with the new token
//       const originalRequest = error.config;
//       originalRequest.headers.Authorization = `Bearer ${ responseToken.token}`;

//       debugger
//       return axios(originalRequest);
//     }
//     debugger
//     console.log(error);
//     return Promise.reject(error);
//   }

// );
// const handelLocal =(responseToken)=>{
//   localStorage.setItem("Token", JSON.stringify( responseToken.token));
//       localStorage.setItem("userProfile", JSON.stringify(responseToken));
// }

// export default jwtInterceptor;

import axios from "axios";
import Environment from "../Environment";

const jwtInterceptor = axios.create();

let isRefreshing = false; // Flag to track if refresh is in progress
let refreshSubscribers = []; // Array to hold pending requests while refresh is in progress

jwtInterceptor.interceptors.request.use((config) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  if (storedToken) {
    config.headers.Authorization = `Bearer ${storedToken}`;
  }

  return config;
});

jwtInterceptor.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      const storedToken = JSON.parse(localStorage.getItem("Token"));
      const userProfile = JSON.parse(localStorage.getItem("userProfile"));

      if (!storedToken) {
        window.location.href = "/account/login";
        throw new Error("No token available for refresh");
      }

      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const id = window.location.href;
          const domain = new URL(id).hostname;

          const refreshResponse = await axios.post(
            `${Environment.Account_Url}refresh-token`,
            {
              accessToken: storedToken,
              refreshToken: userProfile.refreshToken,
              domain: domain === "localhost" ? "dastar.fudeasy.com" : domain,
            }
          );
          const newAccessToken = refreshResponse.data.accessToken;
          const newRefreshToken = refreshResponse.data.refreshToken;

          // Update user profile with new tokens
          const updatedUserProfile = {
            ...userProfile,
            refreshToken: newRefreshToken,
            accessToken: newAccessToken,
          };

          // Update local storage with new tokens and user profile
          handleLocal(newAccessToken, updatedUserProfile);

          // Update stored token for subsequent requests
          jwtInterceptor.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${newAccessToken}`;

          // Execute all pending requests
          refreshSubscribers.forEach((callback) => callback(newAccessToken));
          refreshSubscribers = [];
          return axios(error.config);
        } catch (refreshError) {
          console.error("Error refreshing token:", refreshError);
          throw refreshError;
        } finally {
          isRefreshing = false;
        }
      } else {
        // If refresh is already in progress, wait and retry request after refresh
        return new Promise((resolve) => {
          refreshSubscribers.push((token) => {
            error.config.headers.Authorization = `Bearer ${token}`;
            resolve(axios(error.config));
          });
        });
      }
    }

    console.log(error);
    return Promise.reject(error);
  }
);

const handleLocal = (accessToken, userProfile) => {
  localStorage.setItem("Token", JSON.stringify(accessToken));
  localStorage.setItem("userProfile", JSON.stringify(userProfile));
};

export default jwtInterceptor;

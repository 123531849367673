import React, { useState } from "react";
import { Badge, Button, Card, Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";
import accessLevelData from "../../../Json/Reviewer/user-setip/access-level.json";
import MappedUserModal from "./mapped-user"

export default function AccessLevel() {
    const columns = [
        {
            name: "ID",
            selector: (row) => row.id,
        },
        {
            name: "User Group",
            selector: (row) => (
                <div className="d-flex flex-wrap gap-1 pt-2 pb-2">
                    {row.user_group.map((group, index) => (
                        <Badge key={index} bg="primary" pill className="me-1">
                            {group}
                        </Badge>
                    ))}
                </div>
            ),
        },
        {
            name: "User Count",
            selector: (row) => (<Badge bg="primary" pill>{row.user_count}</Badge>),
        },
        {
            name: "Action",
            selector: () => (
                <div className="d-flex gap-2 justify-content-end mt-md-0 align-items-center">
                    <Button variant="secondary" className="btn-icon">
                        <i className="ri-eye-line"></i>
                    </Button>

                    <Dropdown>
                        <Dropdown.Toggle variant="outline-dark" className="btn-icon custom-dropdown-action">
                            <i className="ri-more-2-fill fs-18"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="#" onClick={() => handleMappedUserModal(true)}>
                                <i className="ri-user-line align-middle"></i> <span className="align-middle">Mapped User</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            ),
        },
    ];

    const [showMappedUserModal, setShowMappedUserModal] = useState(false);
    const handleMappedUserModal = (state) => {
        setShowMappedUserModal(state);
    }

    return (
        <React.Fragment>
            <MappedUserModal show={showMappedUserModal} closeFunction={handleMappedUserModal} />
            <div className="d-md-flex align-items-center justify-content-between mb-3">
                <h3 className="fs-14 mb-1">Access Level</h3>
            </div>

            <Card className="card-one">
                <Card.Body>
                    <div className="custom-common-table">
                        <DataTable
                            columns={columns}
                            data={accessLevelData}
                            fixedHeader
                            search={true}
                            highlightOnHover
                            pagination
                        ></DataTable>
                    </div>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}
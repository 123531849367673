import { combineReducers } from 'redux';
import client from './client';
import common from './common';
import subscription from './subscription';
import users from './users';
import timeSheet from './timeSheet';
import approver from './approver';
import finance from './finance';

export default combineReducers({
    client,
    common,
    subscription,
    users,
    timeSheet,
    approver,
    finance
})
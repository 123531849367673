import React, { useState } from "react";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import 'react-tagsinput/react-tagsinput.css';

export default function CreateClientSetup(props) {
    const [contractType, setContractType] = useState("0");

    const handleContractTypeChange = (e) => {
        const selectedType = e.target.value;
        setContractType(selectedType);
    };

    const selectOpsReviewers = [
        { value: '1', label: 'Nirav Yadav' },
        { value: '2', label: 'Prachi Raut' },
        { value: '3', label: 'Atik Shaikh' },
        { value: '4', label: 'Mandar Sawant' },
    ];
    const selectOpsApprover = [
        { value: '1', label: 'Mahesh Kadam' },
        { value: '2', label: 'Umesh Chavan' },
        { value: '3', label: 'Parasmani' },
        { value: '4', label: 'Mehtab Ansari' },
    ];
    const selectOpsFinanace = [
        { value: '1', label: 'Imran Qureshi' },
        { value: '2', label: 'Akshay Kadam' },
        { value: '3', label: 'Adbul Salam' },
        { value: '4', label: 'Irfan Qadri' },
    ];

    
    const [clientTags, setClientTags] = useState([]);
    const [tags, setTags] = useState([]);

    const handleClientChange = (newTags) => {
        setClientTags(newTags);
    };

    const handleCredenceChange = (newTags) => {
      setTags(newTags);
    };


    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Add New Client Settup</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>

                    <Row>
                        <Col md={4}>
                            <div className="mb-4">
                                <Form.Label>Client Code</Form.Label>
                                <Form.Control type="text" placeholder="Enter Client Code" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <div className="mb-4">
                                <Form.Label>Client Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter Client Name" />
                            </div>
                        </Col>


                    </Row>

                    <Row>
                        <Col md={6}>
                            <div className="mb-4">
                                <Form.Label>Cost Center</Form.Label>
                                <Form.Control type="text" placeholder="Enter Client Name" />
                            </div>
                        </Col>


                    </Row>

                    <Row>
                        <Col md={6}>
                            <div className="mb-4">
                                <Form.Label>Live Date</Form.Label>
                                <Form.Control type="date" />
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className="mb-4">
                                <Form.Label>Onboarding Date</Form.Label>
                                <Form.Control type="date" />
                            </div>
                        </Col>
                    </Row>


                    {/* EMAIL DISTRIBUTION LIST */}
                    <Row>
                        <Col md={12}>
                        <div className="divider divider-start"><span className="fs-16 fw-semibold">Email Distribution List</span></div>
                                <Row>
                                    <Col md={8}>
                                        <div className="mb-4">
                                            <Form.Label>Client Email Address</Form.Label>
                                            <TagsInput className="form-control" value={clientTags} onChange={handleClientChange} />
                                        </div>
                                    </Col>

                                    <Col md={8}>
                                        <div>
                                            <Form.Label>Credence Email Address</Form.Label>
                                            <TagsInput className="form-control" value={tags} onChange={handleCredenceChange} />
                                        </div>
                                    </Col>
                                </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8}>
                            <div className="mb-5">
                                <Form.Label>Contract Type</Form.Label>
                                <Form.Select value={contractType} onChange={handleContractTypeChange}>
                                    <option value="0" disabled selected>Choose Contract Type</option>
                                    <option value="FTE">FTE</option>
                                    <option value="Transaction">Transaction</option>
                                    <option value="Contingency">Contingency</option>
                                    <option value="Flat Fee">Flat Fee</option>
                                </Form.Select>
                            </div>
                        </Col>
                    </Row>


                    {/* SUBSCRIPTION */}
                    <div className="custom-divider pt-4"><span className="divider-title">Subscription</span>
                        <Row>
                            <Col md={8}>
                                <div className="mb-4">
                                    <Form.Label>Subscription Type</Form.Label>
                                    <Form.Select>
                                        <option>Choose Subscription Type</option>
                                        <option>Billable</option>
                                        <option>Non-Billable</option>
                                    </Form.Select>
                                </div>
                            </Col>
                        </Row>


                        {contractType > "" ? (
                            <>
                                <Row>
                                    <Col md={12}>
                                        <div>

                                            {contractType === "FTE" && (
                                                <>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Form.Label>FTE Approved</Form.Label>
                                                                <Form.Control type="text" placeholder="Enter FTE Approved" />
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Form.Label>Transaction Target</Form.Label>
                                                                <div className="input-group">
                                                                    <span className="input-group-text">$</span>
                                                                    <Form.Control className="form-control" placeholder="Enter Transaction Target" type="number" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div>
                                                                <Form.Label>FTE Rate</Form.Label>
                                                                <div className="input-group">
                                                                    <span className="input-group-text">$</span>
                                                                    <Form.Control className="form-control" placeholder="Enter FTE Rate" type="number" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </>

                                            )}

                                            {contractType === "Transaction" && (
                                                <>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div>
                                                                <Form.Label>Transaction Count</Form.Label>
                                                                <div className="input-group">
                                                                    <span className="input-group-text">$</span>
                                                                    <Form.Control className="form-control" placeholder="Enter Transaction Count" type="number" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div>
                                                                <Form.Label>Transaction Rate</Form.Label>
                                                                <div className="input-group">
                                                                    <span className="input-group-text">$</span>
                                                                    <Form.Control className="form-control" placeholder="Enter Transaction Rate" type="number" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}

                                            {contractType === "Contingency" && (
                                                <Row>
                                                    <Col md={6}>
                                                        <div>
                                                            <Form.Label>Percentage</Form.Label>
                                                            <div className="input-group">
                                                                <span className="input-group-text">%</span>
                                                                <Form.Control className="form-control" placeholder="Enter Percentage" type="number" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )}

                                            {contractType === "Flat Fee" && (
                                                <Row>
                                                    <Col md={6}>
                                                        <div>
                                                            <Form.Label>Fee Amount</Form.Label>
                                                            <div className="input-group">
                                                                <span className="input-group-text">$</span>
                                                                <Form.Control className="form-control" placeholder="Enter Fee Amount" type="number" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </>

                        ) : (
                            <></>
                        )}
                    </div>

                    <Row>
                        <Col md={12}>
                            <div className="mt-4">
                                <Form.Label>Operations Reviewers</Form.Label>
                                <Select options={selectOpsReviewers} isSearchable={true} isMulti />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <div className="mt-4">
                                <Form.Label>Operations Approver</Form.Label>
                                <Select options={selectOpsApprover} isSearchable={true} isMulti />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <div className="mt-4">
                                <Form.Label>Finanace Manager</Form.Label>
                                <Select options={selectOpsFinanace} isSearchable={true} isMulti />
                            </div>
                        </Col>
                    </Row>


                </Offcanvas.Body>
                <div className="offcanvas-footer justify-content-start">
                    <Button type="submit" variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Create Client</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
}
import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import TagsInput from "react-tagsinput";
import 'react-tagsinput/react-tagsinput.css';
import { getAllClientmasterById, getAllClientmasterList, getSubscrtiptionData } from "../../../application/selectors/indexSelector";
import { GetClientMasterById, GetClientMasterList } from "../../../application/actions/clientAction";
import { useDispatch, useSelector } from "react-redux";
import { GetSubscription } from "../../../application/actions/subscriptionAction";

export default function CreateClient(props) {
    const dispatch = useDispatch();
    const [Masterclient, setMasterclient] = useState(null);
    const [clientCode, setclientCode] = useState(null);
    const [clientCenter, setclientCenter] = useState(null);
    const [clientEmailList, setClientEmailList] = useState([]); // For TagsInput, initialize as an empty array
    const [credenceEmailList, setCredenceEmailList] = useState([]); // For TagsInput, initialize as an empty array

    const ClietMasterList = useSelector(getAllClientmasterList);
    const ClietMasterById = useSelector(getAllClientmasterById);

    useEffect(() => {
        dispatch(GetClientMasterList);
    }, []);
    const SubscriptionList = useSelector(getSubscrtiptionData);
    useEffect(() => {
      dispatch(GetSubscription);
    }, []);
    useEffect(() => {
        if (Masterclient) {
            console.log("Id", Masterclient);
            if (Masterclient !== "Choose Client") {
                dispatch(GetClientMasterById(Masterclient));
            }else{
                setclientCenter(null);
                setclientCode(null);
            }
        }
    }, [Masterclient]);

    // Update client details when ClietMasterById changes
    useEffect(() => {
        if (ClietMasterById) {
            setclientCenter(ClietMasterById[0]?.clientCenter || "");
            setclientCode(ClietMasterById[0]?.clientCode || "");
        }
    }, [ClietMasterById]);

    const handleClientChange = (event) => {
        const selectedId = event.target.value;
        setMasterclient(selectedId);
    };

    const handleClientEmailChange = (tags) => {
        setClientEmailList(tags);
    };

    const handleCredenceEmailChange = (tags) => {
        setCredenceEmailList(tags);
    };

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Add New Client Setup</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <Col md={4}>
                            <div className="mb-4">
                                <Form.Label>Client Name</Form.Label>
                                <Form.Select onChange={handleClientChange}>
                                    <option>Choose Client</option>
                                    {/* Conditionally check if ClietMasterList exists before calling map */}
                                    {ClietMasterList && ClietMasterList.length > 0 && ClietMasterList.map((Subs) => (
                                        <option key={Subs.id} value={Subs.id}>
                                            {Subs.clienName}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                    </Row>

                    {/* Conditionally render Client Code and Cost Center only when data is available */}
                    {clientCode && (
                        <>
                            <Row>
                                <Col md={4}>
                                    <div className="mb-4">
                                        <Form.Label>Client Code</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            value={clientCode || ""} 
                                            disabled 
                                            placeholder="Enter Client Code" 
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className="mb-4">
                                        <Form.Label>Cost Center</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            value={clientCenter || ""}  
                                            disabled 
                                            placeholder="Enter Cost Center" 
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}

                    {/* Other form fields */}
                    <Row>
                        <Col md={6}>
                            <div className="mb-4">
                                <Form.Label>Live Date</Form.Label>
                                <Form.Control type="date" />
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className="mb-4">
                                <Form.Label>Onboarding Date</Form.Label>
                                <Form.Control type="date" />
                            </div>
                        </Col>
                    </Row>

                    {/* Email Distribution List */}
                    <Row>
                        <Col md={12}>
                            <div className="divider divider-start">
                                <span className="fs-14 fw-semibold">Email Distribution List</span>
                            </div>
                            <Row>
                                <Col md={8}>
                                    <div className="mb-4">
                                        <Form.Label>Client Email Address</Form.Label>
                                        <TagsInput 
                                            value={clientEmailList} 
                                            onChange={handleClientEmailChange} 
                                            className="form-control" 
                                        />
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <div className="mb-4">
                                        <Form.Label>Credence Email Address</Form.Label>
                                        <TagsInput 
                                            value={credenceEmailList} 
                                            onChange={handleCredenceEmailChange} 
                                            className="form-control" 
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8}>
                            <div className="mb-4">
                                <Form.Label>Subscription</Form.Label>
                                <Form.Select>
                                    <option>Choose Subscription</option>
                                    {/* Conditionally check if ClietMasterList exists before calling map */}
                                    {SubscriptionList && SubscriptionList.length > 0 && SubscriptionList.map((Subs) => (
                                        <option key={Subs.id} value={Subs.id}>
                                            {Subs.subsName}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                    </Row>
                </Offcanvas.Body>
                <div className="offcanvas-footer justify-content-start">
                    <Button type="submit" variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Create Client</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    );
}

import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { getCommonListData, getRolesData, getUserGroupData } from "../../../../application/selectors/indexSelector";
import { AddUserDirectory, GetRole, GetUserGroup } from "../../../../application/actions/usersAction";
import { MultiSelect } from "react-multi-select-component";
import { GetCommonList } from "../../../../application/actions/commonAction";

export default function AddNewUserDirectory(props) {
  const dispatch = useDispatch();

  // Define state for form fields
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [roleId, setRoleId] = useState(0);
  const [username, setUsername] = useState("");
  const [status, setStatus] = useState(0);


  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create data object for submission
    const data = {
      fullName: fullName,
      username: username,
      roleId: roleId,
      status: status,
      email: email,
      password: "Pass@123", // You may want to allow custom passwords later
    };

    setFullName("");
      setUsername("");
      setEmail("");
      setRoleId(0);
      setStatus(0);

    try {
      dispatch(AddUserDirectory(data)); // Dispatch action to add user
      props.closeFunction(); // Close the off-canvas on successful submit
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  // Fetch necessary data on component mount
  useEffect(() => {
    dispatch(GetRole);      
  }, [dispatch]);

  // User role options
  const selectUserRoleOptions = [
    { label: "Admin", value: "1" },
    { label: "Operations Reviewer", value: "2" },
    { label: "Operations Approver", value: "6" },
    { label: "Management", value: "4" },
    { label: "Report", value: "5" },
    { label: "Finance Manager", value: "3", disabled: true },
  ];

  // Status options
  const statusOptions = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "2" },
  ];

  return (
    <React.Fragment>
      <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-30">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">Add New User Directory</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            {/* Status Select */}
            <div className="mb-4">
              <Form.Label>Status</Form.Label>
              <Form.Select value={status} onChange={(e) => setStatus(e.target.value)}>
                <option>Choose Status...</option>
                {statusOptions.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Form.Select>
            </div>

            {/* Full Name Input */}
            <div className="mb-3">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Full Name ..."
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>

            {/* User Name Input */}
            <div className="mb-3">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter User Name ..."
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            {/* Email Input */}
            <div className="mb-4">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email Address ..."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            {/* User Role Select */}
            <div className="mb-4">
              <Form.Label>User Role</Form.Label>
              <Form.Select value={roleId} onChange={(e) => setRoleId(e.target.value)}>
                <option>Choose Roles...</option>
                {selectUserRoleOptions.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Form.Select>
            </div>

            {/* Submit Button */}
            <div className="justify-content-start">
              <Button type="submit" variant="primary" className="fs-14 me-2 d-flex align-items-center">
                <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                <span className="align-middle">Add</span>
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
};
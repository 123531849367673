import { GET_ALL_COMMONLIST_SUCCESS, GET_ALL_CONTRACTLIST_SUCCESS } from "../actions/commonAction";

const initialState = {
    commonList: [],
    contractList: [],
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COMMONLIST_SUCCESS:
      return { ...state, commonList: action.payload.result, error: null };
    case GET_ALL_CONTRACTLIST_SUCCESS:
      return { ...state, contractList: action.payload.result, error: null };
    default:
      return state;
  }
};

export default reducer;

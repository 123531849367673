import React, { useState } from "react";
import { Card, Button, Badge } from "react-bootstrap";
import DataTable from "react-data-table-component";
import clientSetupData from "../../../Json/Reviewer/client-setup-.json";
import { Link } from "react-router-dom";
import CreateClientSetup from "./create-client-setup";
import SweetAlertService from "../../_common/SweetAlertService";
import ToastifyService from "../../_common/ToastifyService";
import ClientDetails from "./client-details";

export default function ClientSetup() {
    const columns = [
        {
            name: "Client Name",
            selector: (row) => ( <Link  onClick={() => handleClientDetails(row)} className="fw-semibold text-wrap"
            >
                {row.client_name}
            </Link>)
        },
        {
            name: "Client Code",
            selector: (row) => row?.client_code
        },
        {
            name: "Cost Center",
            selector: (row) => row?.cost_center
        },
        {
            name: "Live Date",
            selector: (row) => row?.live_date
        },
        {
            name: "Onboarding Date",
            selector: (row) => row?.onboarding_date
        },
        {
            name: "Contract Type",
            selector: (row) => row?.contract_type
        },
        {
            name: "Subscription",
            selector: (row) => (<Badge bg={row.subscription === "Non-Billable" ? "danger" : "success"} className="wt-90" pill>{row.subscription}</Badge>)
        },

        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex justify-content-end">
                    <Button variant="primary" className="btn-icon me-2">
                        <i className="ri-pencil-line"></i>
                    </Button>
                    <Button variant="outline-danger" className="btn-icon" onClick={() => handleDeleteClient(row)}>
                        <i className="ri-delete-bin-line"></i>
                    </Button>
                </div>
            ),
        },
    ];

    // HANDLE CLIENT SETUP MODAL
    const [showAddClientSetup, setShowClientSetup] = useState(false);
    const handleClientSetup = (state) => {
        setShowClientSetup(state);
    }
    
    // HANDLE DELETE CLIENT
    const handleDeleteClient = (data) => {
        SweetAlertService.showAlert(
            data.client_name,
            "Are you sure you want to delete this Client?",
            "warning"
        )
        .then((result) => {
            if (result.isConfirmed) {
                ToastifyService.success(`User Deleted Successfully`);
            } else {
                console.log("Delete operation cancelled!");
            }
        })
        .catch((error) => {
            console.error("Error:", error);
            ToastifyService.error(`something went wrong`);
        });
    };


    // CLIENT DETAILS MODAL FUNCTION
    const [clientData, setClientData] = useState(null);
    const [showClientDetails, setShowClientDetails] = useState(false);
    const handleClientDetails = (rowData)=> {
        setClientData(rowData); 
        setShowClientDetails(true);
    };

    
    return (
        <React.Fragment>
            <CreateClientSetup show={showAddClientSetup} closeFunction={handleClientSetup} />
            <ClientDetails show={showClientDetails} closeFunction={()=> setShowClientDetails(false)} clientData={clientData} />
           
            <div className="d-md-flex align-items-center justify-content-between mb-3">
                <h3 className="fs-14 mb-1">Client Setup</h3>
                <div className="d-flex gap-2 mt-3 mt-md-0">
                    <Button variant="primary" className="d-flex align-items-center gap-2" onClick={() => handleClientSetup(true)}>
                        <i className="ri-add-line fs-18 lh-1"></i><span className="d-sm-inline"> Create New Client Setup</span>
                    </Button>
                </div>
            </div>

            <Card className="card-one">
                <Card.Body>
                    <DataTable
                        columns={columns}
                        data={clientSetupData}
                        fixedHeader
                        search={true}
                        highlightOnHover
                        pagination
                    ></DataTable>
                </Card.Body>
            </Card>
        </React.Fragment>
    )

};
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Button, Card, Col, Form, ListGroup, Offcanvas, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GetCommonList, GetContractTypeList } from "../../../application/actions/commonAction";
import { getCommonListData, getContractListData, getMasterSubscrtiptionData } from "../../../application/selectors/indexSelector";
import { GetMasterSub } from "../../../application/actions/subscriptionAction";


export default function CreateSubscription(props) {
  const dispatch = useDispatch();
  let contracttype = [];
  let BilableStatus = [];
  const Comomlist = useSelector(getCommonListData);

  const ContractTypelist = useSelector(getContractListData);
  const MasterList = useSelector(getMasterSubscrtiptionData);
  localStorage.setItem("ContractTypelist", JSON.stringify(Comomlist));

  // Retrieve the data from localStorage and parse it
  const storedData = JSON.parse(localStorage.getItem("ContractTypelist"));

  if (storedData) {
    // Filter data based on fieldTypeCode being "CTT"
    contracttype = ContractTypelist;
    BilableStatus = storedData.filter(item => item.fieldTypeCode === "BS");
  }

  useEffect(() => {
    if (props.show) {
      dispatch(GetCommonList);
      dispatch(GetContractTypeList);
      dispatch(GetMasterSub);
    }
  }, [props.show]);

  const [contractType, setContractType] = useState("0");

  const handleContractTypeChange = (e) => {
    const selectedType = e.target.value;
    setContractType(selectedType);
  };

  const selectOpsReviewers = [
    { value: '1', label: 'Nirav Yadav' },
    { value: '2', label: 'Prachi Raut' },
    { value: '3', label: 'Atik Shaikh' },
    { value: '4', label: 'Mandar Sawant' },
  ];
  const selectOpsApprover = [
    { value: '1', label: 'Mahesh Kadam' },
    { value: '2', label: 'Umesh Chavan' },
    { value: '3', label: 'Parasmani' },
    { value: '4', label: 'Mehtab Ansari' },
  ];

  const selectOpsFinanace = [
    { value: '1', label: 'Imran Qureshi' },
    { value: '2', label: 'Akshay Kadam' },
    { value: '3', label: 'Adbul Salam' },
    { value: '4', label: 'Irfan Qadri' },
  ];

  // UPLOAD PDF FILE MULTIPLE SELECTABLE 
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null); // Ref to access the hidden file input

  // Handle file selection
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]); // Append new files
  };

  // Handle file removal
  const handleRemoveFile = (fileToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  };

  // Simulate file input click
  const handleUploadClick = () => {
    fileInputRef.current.click(); // Trigger the hidden file input

  };

  return (
    <React.Fragment>
      <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">Add New Subscription</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col md={8}>
              <div className="mb-4">
                <Form.Label>Name</Form.Label>
                <Form.Select>
                  <option>Choose Subscription </option>
                  {MasterList.map(item => (
                    <option key={item.subsName} value={item.subsName}>{item.subsName}</option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>
          <Row>
            
      
            <Col md={8}>
              <div className="mb-4">
                <Form.Label>Start Date</Form.Label>
                <Form.Control type="date" />
              </div>
            </Col>

            <Col md={8}>
              <div className="mb-4">
                <Form.Label>Term Date</Form.Label>
                <Form.Control type="date" />
              </div>
            </Col>

            <Col md={8}>
              <div className="mb-4">
                <Form.Label>Billing</Form.Label>
                <Form.Select>
                  <option>Choose Status</option>
                  {BilableStatus.map(item => (
                    <option key={item.id} value={item.id}>{item.fieldValue}</option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <div className="mb-4">
                <Form.Label>Monthly Minimum</Form.Label>
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">$</span>
                  <Form.Control type="number" />
                </div>
              </div>
            </Col>
          </Row>

          {/* SUBSCRIPTION */}
          <div className="custom-divider pt-4 mb-4"><span className="divider-title">Contract Type</span>
            <Row>
              <Col md={8}>
                <div className="mb-4">
                  <Form.Label>Contract Type</Form.Label>
                  <Form.Select value={contracttype} onChange={handleContractTypeChange}>
                 
                  <option>Choose Contract Type</option>
                  {contracttype.map(item => (
                    <option key={item.id} value={item.id}>{item.contractName}</option>
                  ))}
                
                  </Form.Select>
                </div>
              </Col>
            </Row>

            {/* SUBSCRIPTION */}
            {contractType > "" ? (
              <>
                <Row>
                  <Col md={12}>
                    <div>

                      {contractType === "6fd68c4c-c334-4395-8ec5-7545fe858482" && (
                        <>
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Form.Label>FTE Approved</Form.Label>
                                <Form.Control type="text" placeholder="Enter FTE Approved" />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Form.Label>Transaction Target</Form.Label>
                                <div className="input-group">
                                  <span className="input-group-text">$</span>
                                  <Form.Control className="form-control" placeholder="Enter Transaction Target" type="number" />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div>
                                <Form.Label>FTE Rate</Form.Label>
                                <div className="input-group">
                                  <span className="input-group-text">$</span>
                                  <Form.Control className="form-control" placeholder="Enter FTE Rate" type="number" />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>

                      )}

                      {contractType === "cb3fc135-0c8e-451b-a866-e82d6a2d42e2" && (
                        <>
                          <Row>
                            <Col md={6}>
                              <div>
                                <Form.Label>Transaction Count</Form.Label>
                                <div className="input-group">
                                  <span className="input-group-text">$</span>
                                  <Form.Control className="form-control" placeholder="Enter Transaction Count" type="number" />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div>
                                <Form.Label>Transaction Rate</Form.Label>
                                <div className="input-group">
                                  <span className="input-group-text">$</span>
                                  <Form.Control className="form-control" placeholder="Enter Transaction Rate" type="number" />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}

                      {contractType === "e36e3ef5-5431-40df-9a1c-73e5573de8a9" && (
                        <Row>
                          <Col md={6}>
                            <div>
                              <Form.Label>Percentage</Form.Label>
                              <div className="input-group">
                                <span className="input-group-text">%</span>
                                <Form.Control className="form-control" placeholder="Enter Percentage" type="number" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}

                      {contractType === "b394a26c-bfeb-4ddd-b36b-ee7997816ce0" && (
                        <Row>
                          <Col md={6}>
                            <div>
                              <Form.Label>Fee Amount</Form.Label>
                              <div className="input-group">
                                <span className="input-group-text">$</span>
                                <Form.Control className="form-control" placeholder="Enter Fee Amount" type="number" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </Col>
                </Row>
              </>

            ) : (
              <></>
            )}
          </div>

          {/* UPLOAD PDF FILE MULTIPLE SELECTABLE */}
          <div className="file-upload">
            <Form.Group controlId="formFileMultiple" className="mb-4">
              <Form.Control
                type="file"
                ref={fileInputRef} // Attach ref to input
                style={{ display: "none" }} // Hide the default input
                multiple
                accept="application/pdf"
                onChange={handleFileChange}
              />
            </Form.Group>

            {/* Custom button to trigger file upload */}
            <Button variant="primary" onClick={handleUploadClick}>
              <i className="ri-upload-line align-middle"></i> Select PDF Files
            </Button>
            {/* <Button variant="primary" onClick={handleUpload}>Upload Files</Button> */}
            {files.length > 0 && (
              <div className="mt-4">
                <h5 className="fs-14 fw-semibold mb-2">Selected Files:</h5>
                <Card className="card-one">
                  <Card.Body className="pb-0">
                    <ListGroup as="ul" className="list-group-one">
                      {files.map((file, index) => (
                        <ListGroup.Item
                          key={index}
                          as="li"
                          className="px-0 d-flex align-items-center justify-content-between gap-2"
                        >
                          <div>
                            <i className="ri-file-pdf-line fs-22 align-middle"></i>{" "}
                            {file.name}
                          </div>
                          <div>
                            <Button
                              variant="danger"
                              className="btn-icon"
                              onClick={() => handleRemoveFile(file)}
                            >
                              <i className="ri-close-line"></i>
                            </Button>
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Card.Body>
                </Card>
              </div>
            )}
          </div>

          {/* OPERATIONS REVIEWER */}
          <Row>
            <Col md={8}>
              <div className="mt-4">
                <Form.Label>Operations Reviewer</Form.Label>
                <Select options={selectOpsReviewers} isSearchable={true} isMulti />
              </div>
            </Col>
          </Row>

          {/* OPERATIONS APPROVER */}
          <Row>
            <Col md={8}>
              <div className="mt-4">
                <Form.Label>Operations Approver</Form.Label>
                <Select options={selectOpsApprover} isSearchable={true} isMulti />
              </div>
            </Col>
          </Row>

          {/* FINANCE MANAGER */}
          <Row>
            <Col md={8}>
              <div className="mt-4">
                <Form.Label>Finanace Manager</Form.Label>
                <Select options={selectOpsFinanace} isSearchable={true} isMulti />
              </div>
            </Col>
          </Row>

        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            type="submit"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Create Subscription</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}

import React, { useState, useEffect } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { format, subDays } from "date-fns";

export default function MonthsviewModal(props) {
    const [gettimeSheetData, setGettimeSheetData] = useState(null);
    const [data, setData] = useState([]);
    const [editId, setEditId] = useState(null);
    const [editedRow, setEditedRow] = useState({});

    useEffect(() => {
        if (props.data) {
            setGettimeSheetData(props.data);
            setData(props.data.month_data || []);
        }
    }, [props.data]);

    // Generate 30 days of mock data (if needed)
    const generateData = () => {
        const today = new Date();
        return Array.from({ length: 30 }).map((_, i) => {
            const date = subDays(today, i);
            return {
                id: i + 1,
                day: format(date, "yyyy-MM-dd"),
                transactions: Math.floor(Math.random() * 200),
                clientCollections: Math.floor(Math.random() * 5000),
                fteDeployed: Math.floor(Math.random() * 10),
                operationsRemarks: "On track",
                financeRemarks: "Clear",
                transactionAmount: Math.floor(Math.random() * 10000),
                fteAmount: Math.floor(Math.random() * 5000),
                contingencyAmount: Math.floor(Math.random() * 1000),
                invoiceAmount: Math.floor(Math.random() * 15000),
            };
        });
    };

    useEffect(() => {
        if (data.length === 0) {
            setData(generateData()); // Generate mock data if no data is present
        }
    }, [data]);

    const handleEditClick = (row) => {
        setEditId(row.id);
        setEditedRow({ ...row });
    };

    const handleSaveClick = () => {
        setData(data.map((item) => (item.id === editId ? editedRow : item)));
        setEditId(null);
    };

    const handleInputChange = (e, field) => {
        setEditedRow({ ...editedRow, [field]: e.target.value });
    };

    const columns = [
        {
            name: "Day",
            selector: (row) => row?.day,
        },
        {
            name: "Transactions",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.transaction}
                        onChange={(e) => handleInputChange(e, 'transaction')}
                    />
                ) : (
                    row.transaction
                ),
        },
        {
            name: "Client Collections",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.client_collection}
                        onChange={(e) => handleInputChange(e, 'client_collection')}
                    />
                ) : (
                    row.client_collection
                ),
        },
        {
            name: "FTE Deployed",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.FTE_deployed}
                        onChange={(e) => handleInputChange(e, 'FTE_deployed')}
                    />
                ) : (
                    row.FTE_deployed
                ),
        },
        {
            name: "Operations Remarks",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="text"
                        value={editedRow.operation_remarks}
                        onChange={(e) => handleInputChange(e, 'operation_remarks')}
                    />
                ) : (
                    row.operation_remarks
                ),
        },
        {
            name: "Finance Remarks",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="text"
                        value={editedRow.finance_remark}
                        onChange={(e) => handleInputChange(e, 'finance_remark')}
                    />
                ) : (
                    row.finance_remark
                ),
        },
        {
            name: "Transaction Amount",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.transaction_amt}
                        onChange={(e) => handleInputChange(e, 'transaction_amt')}
                    />
                ) : (
                    <>${row.transaction_amt}</>
                ),
        },
        {
            name: "FTE Amount",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.fte_amt}
                        onChange={(e) => handleInputChange(e, 'fte_amt')}
                    />
                ) : (
                    <>${row.fte_amt}</>
                ),
        },
        {
            name: "Contingency Amount",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.contingency_amount}
                        onChange={(e) => handleInputChange(e, 'contingency_amount')}
                    />
                ) : (
                    <>${row.contingency_amount}</>
                ),
        },
        {
            name: "Invoice Amount",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.invoice_amt}
                        onChange={(e) => handleInputChange(e, 'invoice_amt')}
                    />
                ) : (
                    <>${row.invoice_amt}</>
                ),
        },
        {
            name: "Actions",
            cell: (row) =>
                row.id === editId ? (
                    <Button variant="success" onClick={handleSaveClick}>Save</Button>
                ) : (
                    <Button variant="primary" className="btn-icon" onClick={() => handleEditClick(row)}><i className="ri-pencil-line"></i></Button>
                ),
        },
    ];

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-90">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">{gettimeSheetData?.month_year}</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <DataTable
                        columns={columns}
                        data={data}
                        fixedHeader
                        highlightOnHover
                        pagination
                        className="min-height-auto max-height-none"
                        paginationPerPage={31}
                    />
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-between">
                    <div>
                        <Form.Control as="textarea" className="wt-500" placeholder="Remark.." />
                    </div>
                    <div className="d-flex gap-2 ">
                        <Button type="button" className="text-uppercase" variant="outline-danger">Reject</Button>
                        <Button type="button" className="text-uppercase" variant="outline-primary">Approve</Button>
                    </div>
                </div>

            </Offcanvas>
        </React.Fragment>
    );
}

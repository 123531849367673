import axios from "axios";
import Environment from "../../../core/Environment";

const GetSubscriptionList = async () => {
  try {
    const response = await axios.get(`${Environment.Subscription_Url}GetSubscriptionList`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error; // Optionally re-throw to handle in the calling component
  }
};
const GetMaterSubsList = async () => {
  try {
    const response = await axios.get(`${Environment.Subscription_Url}GetMater-SubsList`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error; // Optionally re-throw to handle in the calling component
  }
};
const GetUserSubscriptionId = async (Id) => {
  try {
    const response = await axios.get(`${Environment.Subscription_Url}Get_Client_SubscriptionsById/${Id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error; // Optionally re-throw to handle in the calling component
  }
};
const DeleteMaterSubscription = async (Id) => {
  try {
    const response = await axios.get(`${Environment.Subscription_Url}DeleteMasterSubscriptionById/${Id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error; // Optionally re-throw to handle in the calling component
  }
};

const AddMaterSubscription = async (data) => {
  try {
    const response = await axios.post(`${Environment.Subscription_Url}MasterAddSubscription`, data);
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};
export default {
    GetSubscriptionList,GetUserSubscriptionId,GetMaterSubsList,AddMaterSubscription,DeleteMaterSubscription
};

export const GET_TIME_SHEET_FINANACE_COUNT = "GET_TIME_SHEET_FINANACE_COUNT";
export const GET_TIME_SHEET_FINANACE_SUCCESS = "GET_TIME_SHEET_FINANACE_SUCCESS";
export const GET_FINANACE_TIME_SHEET_DETAILS = "GET_FINANACE_TIME_SHEET_DETAILS";
export const GET_FINANACE_TIME_SHEET_DETAILS_SUCCESS = "GET_FINANACE_TIME_SHEET_DETAILS_SUCCESS";
export const GET_APP_CLIENTS_List_BY_ID = "GET_APP_CLIENTS_List_BY_ID";
export const ADD_FINANCE_TIME_SHEET_STATUS = "ADD_FINANCE_TIME_SHEET_STATUS";

export const GetTimeSheetFinanceCount = {
  type: GET_TIME_SHEET_FINANACE_COUNT,
};

export const GetTimeSheetFinanceSuccess = (data) => ({
  type: GET_TIME_SHEET_FINANACE_SUCCESS,
  payload: data,
});
export const GetFinanceTimeSheetDetails = {
  type: GET_FINANACE_TIME_SHEET_DETAILS,
};

export const GetFinanceTimeSheetDetailsSuccess = (data) => ({
  type: GET_FINANACE_TIME_SHEET_DETAILS_SUCCESS,
  payload: data,
});


export const GetClientDetailsBYId = (data) => ({
  type: GET_APP_CLIENTS_List_BY_ID,
  payload: data,
});


export const AddFinanceTimeSheetStatus = (data) => ({
  type: ADD_FINANCE_TIME_SHEET_STATUS,
  payload: data,
});

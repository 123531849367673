import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import UserRoles from "./UserRole/user-role";
import UserGroups from "./UserGroup/user-group";
import UserDirectory from "./UserDirectory/user-directory";

export default function UserSetup() {
    const [activeTab, setActiveTab] = useState('user_role_tab');
    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };
    return (
        <React.Fragment>
            <div className="d-md-flex align-items-center justify-content-between mb-3">
                <h3 className="fs-14 mb-1">User Setup</h3>
            </div>

            <Tabs id="custom-styled-tabs" activeKey={activeTab} onSelect={handleTabSelect} className="common-in-tabs bg-transparent">
                <Tab eventKey="user_role_tab" title={<>User Role</>} tabClassName="custom-tab-header fw-semibold">
                    <div className="custom-tab-body pt-3">
                       <UserRoles />
                    </div>
                </Tab>

                <Tab eventKey="user_group_tab" title={<>Clients</>} tabClassName="custom-tab-header fw-semibold">
                    <div className="custom-tab-body pt-3">
                       <UserGroups />
                    </div>
                </Tab>

                <Tab eventKey="user_directory_tab" title={<>User Directory</>} tabClassName="custom-tab-header fw-semibold">
                    <div className="custom-tab-body pt-3">
                        <UserDirectory />
                    </div>
                </Tab>
            </Tabs>
        </React.Fragment>
    )
};
import React, { useState } from "react";
import Header from "../../layouts/Header";
import projectionlist from "../../Json/Projection/projection.json";
import { Badge, Button, Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import WeeksModal from "./weeks-wise";

export default function Projection() {
    const [projectionData, setProjectionData] = useState(null);
    const [showWeeksSheet, setShowWeeksSheet] = useState(false);
    const [weeklyData, setWeeklyData] = useState([]);

    // Function to parse the day into a JavaScript Date object
    const parseDate = (dayString) => {
        return new Date(dayString);
    };

    // Function to get weekly data (Thursday to Thursday)
    const getWeeklyData = (monthData) => {
        let weeklyData = [];
        let currentWeek = [];

        monthData.forEach((dayData) => {
            const dayDate = parseDate(dayData.day);
            const dayOfWeek = dayDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

            // Check if the current day is Thursday (dayOfWeek = 4)
            if (dayOfWeek === 4) {
                if (currentWeek.length > 0) {
                    weeklyData.push(currentWeek);
                    currentWeek = [];
                }
            }
            currentWeek.push(dayData);
        });

        if (currentWeek.length > 0) {
            weeklyData.push(currentWeek); // Push the last week if it exists
        }

        return weeklyData;
    };

    // Handle action button click
    const handleWeeksSheet = (rowData) => {
        const filteredWeeklyData = getWeeklyData(rowData.month_data); // Filter data for the selected row
        setProjectionData(rowData); // Set the row data
        setWeeklyData(filteredWeeklyData); // Set the filtered weekly data
        setShowWeeksSheet(true); // Show the modal
    };

    // Columns configuration for DataTable
    const columns = [
        {
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Months",
            selector: (row) => row.month,
            sortable: true,
        },
        {
            name: "Timesheet Status",
            selector: (row) => (
                <Badge bg={row.timesheet_status === "Pending" ? "warning" : "success"} pill>{row.timesheet_status}</Badge>
            ),
            sortable: true,
        },
        {
            name: "Client/Subscription",
            selector: (row) => (
                <div>
                    <h5 className="fs-14 mb-1">{row.client_name} - {row.subscription}</h5>
                </div>
            ),
            sortable: true,
        },
        {
            name: "Approver",
            selector: (row) => (
                <Badge bg={row.approver_status === "Pending" ? "danger" : "success"} pill>{row.approver_status}</Badge>
            ),
            sortable: true,
        },
        {
            name: "Action",
            selector: (row) => (
                <Button variant="outline-dark" className="btn-icon" onClick={() => handleWeeksSheet(row)}>
                    <i className="ri-eye-line"></i>
                </Button>
            ),
        },
    ];

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredEmployees = projectionlist.monthly_data?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    return (
        <React.Fragment>
            <Header />
            <WeeksModal
                show={showWeeksSheet}
                closeFunction={() => setShowWeeksSheet(false)}
                data={projectionData}
                weeklyData={weeklyData} // Pass weekly data to modal
            />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0">Projection</h4>
                    </div>
                </div>

                <Card className="card-one">
                    <Card.Header className="align-items-center justify-content-between">
                        <h4 className="main-title fs-14 mb-0">List of Projection</h4>
                        <div className="custom-dropdown-wrapper">
                            <div className="custom-drop-down z-index-2 wt-300">
                                <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                                <Form.Control
                                    type="text"
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <DataTable
                            columns={columns}
                            data={filteredEmployees}
                            fixedHeader
                            highlightOnHover
                            pagination
                            paginationPerPage={12}
                        />
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    );
}
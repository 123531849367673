import React, { useState } from "react";
import Header from "../../layouts/Header";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import Select from "react-select";
import financeManagerData from "../../Json/FinanceManager/finance-manager.json";
import MonthsviewModal from "./months/months-wise";

export default function Management() {
    // CLIENT OPTION DATA
    const clientOptions = [
        { value: '1', label: 'Larsen & Toubro Infotech (LTI)' },
        { value: '2', label: 'Oracle Financial Services Software' },
        { value: '3', label: 'Bharat Petroleum Corporation Limited (BPCL)' }
    ];

    // SUBSCRIIPTION OPTION DATA
    const subscriptionOptions = [
        { value: '1', label: 'Billable' },
        { value: '2', label: 'Non-Billable' },
    ];

    const totalTransactions = financeManagerData.reduce((sum, item) => sum + Number(item.transactions), 0);
    const totalClientCollections = financeManagerData.reduce((sum, item) => sum + Number(item.client_collections), 0);
    const totalFTEDeployed = financeManagerData.reduce((sum, item) => sum + Number(item.FTE_deployed), 0);
    const totalTransactionAmount = financeManagerData.reduce((sum, item) => sum + Number(item.transaction_amount), 0);
    const totalFTEAmount = financeManagerData.reduce((sum, item) => sum + Number(item.FTE_amount), 0);
    const totalContingencyAmount = financeManagerData.reduce((sum, item) => sum + Number(item.contingency_amount), 0);
    const totalInvoiceAmount = financeManagerData.reduce((sum, item) => sum + Number(item.invoice_amount), 0);

    const [monthViewData, setMonthViewtData] = useState(null);
    const [showMonthsview, setShowMonthsview] = useState(false);
    const handleMonthView = (rowData) => {
        setMonthViewtData(rowData);
        setShowMonthsview(true);
    }

    return (

        <React.Fragment>

            <Header />
            <MonthsviewModal show={showMonthsview} closeFunction={() => setShowMonthsview(false)} data={monthViewData} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0">Management</h4>
                    </div>
                </div>

                <Row className="g-3 mb-3">
                    {[
                        {
                            "label": "Transactions",
                            "icon": "ri-shopping-bag-3-line",
                            "value": "48",
                            "percent": "0.7",
                            "status": "down"
                        }, {
                            "label": "Client Collections",
                            "icon": "ri-briefcase-4-line",
                            "value": "88",
                            "percent": "2.1",
                            "status": "up"
                        }, {
                            "label": "FTE Deployed",
                            "icon": "ri-inbox-line",
                            "value": "75",
                            "percent": "0.3",
                            "status": "down"
                        }
                    ].map((card, index) => (
                        <Col xs={6} xl={4} key={index}>
                            <Card className="card-one">
                                <Card.Body>
                                    <Card.Title as="label" className="fs-sm fw-medium mb-1">{card.label}</Card.Title>
                                    <h3 className="card-value mb-1"><i className={card.icon}></i> {card.value}</h3>
                                    <small><span className={"d-inline-flex text-" + ((card.status === "up") ? "success" : "danger")}>{card.percent}% <i className={"ri-arrow-" + ((card.status === "up") ? "up" : "down") + "-line"}></i></span> than last week</small>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>

                <Card className="card-one">
                    <Card.Header className="gap-3 justify-content-end">
                        <div className="wt-300">
                            <Form.Label>Chose Client</Form.Label>
                            <Select options={clientOptions} isSearchable={true} />
                        </div>
                        <div className="wt-300">
                            <Form.Label>Choose Subscription</Form.Label>
                            <Select options={subscriptionOptions} isSearchable={true} />
                        </div>
                        <div className="wt-300">
                            <Form.Label>Choose Month & Year</Form.Label>
                            <Form.Control type="date" />
                        </div>
                        <div>
                            <Button type="button" className="mt-4" variant="primary">Filter <i className="ri-filter-line"></i></Button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div>
                            <Table className='mb-0' responsive>
                                <thead>
                                    <tr>
                                        <th>Month / Year</th>
                                        <th>Transactions</th>
                                        <th>Client Collections</th>
                                        <th>FTE Deployed</th>
                                        <th>Operations Remarks</th>
                                        <th>Finance Remarks</th>
                                        <th>Transaction Amount</th>
                                        <th>FTE Amount</th>
                                        <th>Contingency amount</th>
                                        <th>Invoice Amount</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {financeManagerData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.month_year}</td>
                                            <td>{item.transactions}</td>
                                            <td>{item.client_collections}</td>
                                            <td>{item.FTE_deployed}</td>
                                            <td>{item.operations_remarks}</td>
                                            <td>{item.finance_remarks}</td>
                                            <td>${item.transaction_amount}</td>
                                            <td>${item.FTE_amount}</td>
                                            <td>${item.contingency_amount}</td>
                                            <td>${item.invoice_amount}</td>
                                            <td>
                                                <Button variant="outline-dark" className="btn-icon" onClick={() => handleMonthView(item)}>
                                                    <i className="ri-eye-line"></i>
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                                <tfoot>
                                    <tr>
                                        <th>Total</th>
                                        <th>{totalTransactions}</th>
                                        <th>{totalClientCollections}</th>
                                        <th>{totalFTEDeployed}</th>
                                        <th>-</th>
                                        <th>-</th>
                                        <th>${totalTransactionAmount}</th>
                                        <th>${totalFTEAmount}</th>
                                        <th>${totalContingencyAmount}</th>
                                        <th>${totalInvoiceAmount}</th>
                                        <th></th>
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    )
};
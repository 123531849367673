import React, { useState } from "react";
import Header from "../../layouts/Header";
import { Nav, Tab } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import UnbilledInvoice from "./unbilled-invoice";
import UnpaidInvoice from "./unpaid-invoice";
import PaidInvoice from "./paid-invoice";

export default function Invoices() {
    const [isSidebarShow, setSidebarShow] = useState(false);

    return (
        <React.Fragment>
            <Header />

            <Tab.Container id="left-tabs-example" defaultActiveKey="unbilled_invoice_tab">
                <div className={"main main-file-manager" + (isSidebarShow ? " show" : "")}>
                    <PerfectScrollbar className="file-sidebar page-in-tabs">
                        <Nav variant="pills">
                            <Nav.Item><Nav.Link eventKey="unbilled_invoice_tab"><i className="ri-sticky-note-line"></i> Unbilled Invoice</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="unpaid_invoice_tab"><i className="ri-user-2-line"></i> Unpaid Invoice</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="paid_invoice_tab"><i className="ri-mail-volume-line"></i> Paid Invoice</Nav.Link></Nav.Item>
                        </Nav>
                    </PerfectScrollbar>

                    <PerfectScrollbar className="file-content p-3 p-lg-4">
                    <Link href="" className="menu-file-manager" onClick={() => setSidebarShow(!isSidebarShow)}>
                            <i className="ri-arrow-left-line"></i>
                        </Link>

                        <Tab.Content>
                             {/* CLIENT TAB */}
                             <Tab.Pane eventKey="unbilled_invoice_tab"><UnbilledInvoice /></Tab.Pane>

                              {/* CLIENT TAB */}
                            <Tab.Pane eventKey="unpaid_invoice_tab"><UnpaidInvoice /></Tab.Pane>

                             {/* CLIENT TAB */}
                             <Tab.Pane eventKey="paid_invoice_tab"><PaidInvoice /></Tab.Pane>
                        </Tab.Content>
                    </PerfectScrollbar>
                </div>
            </Tab.Container>
        </React.Fragment>
    )

}

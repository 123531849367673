import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Badge, Card, Col, Dropdown, Form, Nav, Offcanvas, Row, Tab } from "react-bootstrap";
import "./client-details.scss"
import { Link } from "react-router-dom";

export default function ClientDetails(props) {
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Link to="" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e);}} className="dropdown-link">{children}</Link>
    ))

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-90">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Client Details</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row className="g-3 mb-3">
                        {[
                            {
                                "label": "Transactions",
                                "icon": "ri-shopping-bag-3-line",
                                "value": "82",
                                "percent": "0.7",
                                "status": "down"
                            }, {
                                "label": "Client Collections",
                                "icon": "ri-briefcase-4-line",
                                "value": "97",
                                "percent": "2.1",
                                "status": "up"
                            }, {
                                "label": "FTE Deployed",
                                "icon": "ri-inbox-line",
                                "value": "108",
                                "percent": "0.3",
                                "status": "down"
                            },
                            {
                                "label": "FTE Deployed",
                                "icon": "ri-inbox-line",
                                "value": "108",
                                "percent": "0.3",
                                "status": "down"
                            }
                        ].map((card, index) => (
                            <Col xs={6} xl={3} key={index}>
                                <Card className="card-one">
                                    <Card.Body>
                                        <Card.Title as="label" className="fs-sm fw-medium mb-1">{card.label}</Card.Title>
                                        <h3 className="card-value mb-1"><i className={card.icon}></i> {card.value}</h3>
                                        <small><span className={"d-inline-flex text-" + ((card.status === "up") ? "success" : "danger")}>{card.percent}% <i className={"ri-arrow-" + ((card.status === "up") ? "up" : "down") + "-line"}></i></span> than last week</small>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Row className="g-3">
                        <Col xl={9} md={8} sm={12}>
                            {/* CLIENT DETAILS DATA */}
                            <Card className="card-one mb-2 h-auto">
                                <Card.Body>
                                    <Form.Label className="mb-0">Client Name</Form.Label>
                                    <h2 className="fs-16 text-dark fw-semibold">{props.clientData?.client_name}</h2>
                                    <Row className="g-3 align-items-center flex-100 mt-3">
                                        <Col xl={2} md={3} xs={6}>
                                            <h5 className="fs-14">Cost Center</h5>
                                            <h3 className="fs-14 text-dark fw-bold">{props.clientData?.cost_center}</h3>
                                        </Col>
                                        <Col xl={2} md={3} xs={6}>
                                            <h5 className="fs-14">Live Date</h5>
                                            <h3 className="fs-14 text-dark fw-bold">{props.clientData?.live_date}</h3>
                                        </Col>
                                        <Col xl={2} md={3} xs={6}>
                                            <h5 className="fs-14">Onboarding Date</h5>
                                            <h3 className="fs-14 text-dark fw-bold">{props.clientData?.onboarding_date}</h3>
                                        </Col>
                                        <Col xl={2} md={3} xs={6}>
                                            <h5 className="fs-14">Contract Type</h5>
                                            <h3 className="fs-14 text-dark fw-bold">{props.clientData?.contract_type}</h3>
                                        </Col>
                                        <Col xl={2} md={3} xs={6}>
                                            <h5 className="fs-14">Subscription</h5>
                                            <h3 className="fs-14 text-dark fw-bold">
                                                <Badge pill>{props.clientData?.subscription}</Badge>
                                            </h3>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* CLEINT DETAILS TABS */}
                            <Card className="card-one client-details-tab">
                                <Card.Body className="custom-file-sidebar">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="details_tab">
                                        <PerfectScrollbar className="file-sidebar page-in-tabs">
                                            <Nav variant="pills">
                                                <Nav.Item>
                                                    <Nav.Link className="position-relative" eventKey="details_tab">Details</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link className="position-relative" eventKey="activity_log_tab">Activity Log</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link className="position-relative" eventKey="change_log_tab">Change Log</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link className="position-relative" eventKey="invoice_list_tab">Invoice List</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </PerfectScrollbar>

                                        <PerfectScrollbar className="file-content p-3 p-lg-4">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="details_tab">Details</Tab.Pane>
                                                <Tab.Pane eventKey="activity_log_tab">Activity Log</Tab.Pane>
                                                <Tab.Pane eventKey="change_log_tab">Change Log</Tab.Pane>
                                                <Tab.Pane eventKey="invoice_list_tab">Invoice List</Tab.Pane>
                                            </Tab.Content>
                                        </PerfectScrollbar>
                                    </Tab.Container>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs={12} md={12} xl={3}>
                            <Card className="card-one h-auto">
                                <Card.Header>Contract Type List</Card.Header>
                                <Card.Body>
                                    <Row className="g-2 g-lg-3 mb-5">
                                        {[
                                            {
                                                "name": "Downloads",
                                                "files": 2,
                                                "size": "14.05MB"
                                            }, {
                                                "name": "Personal Stuff",
                                                "files": 8,
                                                "size": "76.3MB"
                                            }, {
                                                "name": "3D Objects",
                                                "files": 5,
                                                "size": "126.8MB"
                                            }, {
                                                "name": "Recordings",
                                                "files": 0
                                            }, {
                                                "name": "Support",
                                                "files": 1,
                                                "size": "268.1KB"
                                            }, {
                                                "name": "Cloud Files",
                                                "files": 23,
                                                "size": "199.1MB"
                                            }
                                        ].map((folder, index) => (
                                            <Col xl="12" key={index}>
                                                <Card className="card-folder">
                                                    <Dropdown align="end" className="dropdown-file z-index-2">
                                                        <Dropdown.Toggle as={CustomToggle}>
                                                            <i className="ri-more-2-fill"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>

                                                            <Dropdown.Item href="#" className="download"><i className="ri-download-2-line"></i> Download</Dropdown.Item>
                                                            <Dropdown.Item href="#" className="delete"><i className="ri-delete-bin-line"></i> Delete</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <Card.Body>
                                                        <i className="ri-folder-2-line fs-28"></i>
                                                        <div className="card-content">
                                                            <h6><Link href="" className="link-02">{folder.name}</Link></h6>
                                                            <span>{folder.files} files{folder.size && (", " + folder.size)}</span>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    )
}
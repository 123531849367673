import { GET_ALL_ROLES,  GetAllRolesSuccess,GET_ALL_USERGROUP, GetAllUserGroupSuccess, GET_ALL_USERDIRECTORY, GetAllUserDirectorySuccess, ADD_USER_DIRECTORY } from "../actions/usersAction";

const getRoleData =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {

    if (action.type === GET_ALL_ROLES) {
      try {
      const getrolesdata = await api.usersAPI.GetAllRolesList();
    
      dispatch(GetAllRolesSuccess(getrolesdata));
    }catch (error) {
      if (error.response.data === "Invalid access token or refresh token") {
        localStorage.removeItem("Token");
      }
      }
    }
    next(action);
  };
  const getUserGroupData =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
    if (action.type === GET_ALL_USERGROUP) {
      try {
      const getUsergroupdata = await api.usersAPI.GetAllUsersGroupList();
      dispatch(GetAllUserGroupSuccess(getUsergroupdata));
    }catch (error) {
      if (error.response.data === "Invalid access token or refresh token") {
        localStorage.removeItem("Token");
      }
      }
    }
    next(action);
  };
  const getUserDirectoryData =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
    if (action.type === GET_ALL_USERDIRECTORY) {
      try {
      const getUserdirectorydata = await api.usersAPI.GetAllUsersDirectoryList();
      dispatch(GetAllUserDirectorySuccess(getUserdirectorydata));
    }catch (error) {
      if (error.response.data === "Invalid access token or refresh token") {
        localStorage.removeItem("Token");
      }
      }
    }
    next(action);
  };
  
  const AddUserDirec =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
    if (action.type === ADD_USER_DIRECTORY) {
      try {
      const AddDirectorydata = await api.usersAPI.AddUserDirectoryApi(action.payload);
      const getUserdirectorydata = await api.usersAPI.GetAllUsersDirectoryList();
      dispatch(GetAllUserDirectorySuccess(getUserdirectorydata));
    }catch (error) {
      if (error.response.data === "Invalid access token or refresh token") {
        localStorage.removeItem("Token");
      }
      }
    }
    next(action);
  };
  
export default [getRoleData,getUserGroupData,getUserDirectoryData,AddUserDirec];


import { GET_ALL_COMMONLIST,  GET_ALL_CONTRACTLIST,  getCommonListSuccess, getContractListSuccess } from "../actions/commonAction";

const getData =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
    if (action.type === GET_ALL_COMMONLIST) {
      try {
      const getCommonData = await api.commonAPI.GetCommonListById();
      dispatch(getCommonListSuccess(getCommonData));
    }catch (error) {
      if (error.response.data === "Invalid access token or refresh token") {
        localStorage.removeItem("Token");
      }
      }
    }
    next(action);
  };
  const ContractType =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
    if (action.type === GET_ALL_CONTRACTLIST) {
      try {
      const getContractData = await api.commonAPI.GetContract();
      dispatch(getContractListSuccess(getContractData));
      }catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");

        }
      }
    }
    next(action);
  };

export default [getData,ContractType];


const dashboardMenu = [
  {
    "label": "Dashboard",
    "link": "/dashboard/manage",
    "icon": "ri-pie-chart-2-line"
  },
];


// const AdminMenu = [
//   {
//     "label": "Reviewer",
//     "link": "/reviewer/manage",
//     "icon": "ri-user-line"
//   }
// ];

// const OperationsReviewerMenu = [];
// const OperationsApproverMenu = [];

const TimesheetMenu = [
  {
    "label": "Timesheet",
    "link": "/timesheet/manage",
    "icon": "ri-calendar-line"
  }
];

const ProjectionMenu = [
  {
    "label": "Projection",
    "link": "/projection/manage",
    "icon": "ri-projector-line"
  }
];

const ApproverMenu = [
  {
    "label": "Approver",
    "link": "/approver/manage",
    "icon": "ri-check-double-line"
  }
];

const FinanaceManagerMenu = [
  {
    "label": "Finanace Manager",
    "link": "/finanaceManager/manage",
    "icon": "ri-user-2-line"
  }
];

const ManagementMenu = [
  {
    "label": "Management",
    "link": "/management/manage",
    "icon": "ri-user-settings-line"
  }
];

const InvoiceMenu = [
  {
    "label": "Invoice",
    "link": "/invoice/manage",
    "icon": "ri-sticky-note-line"
  }
];

const InvoiceTemplateMenu = [
  {
    "label": "Invoice Template",
    "link": "/invoicetemplate/manage",
    "icon": "ri-sticky-note-line"
  }
];

const settingsMenu = [
  {
    "label": "System Settings",
    "link": "/setting/manage",
    "icon": "ri-tools-line"
  }
];


export { dashboardMenu, TimesheetMenu, ProjectionMenu, ApproverMenu, FinanaceManagerMenu, ManagementMenu, InvoiceMenu, InvoiceTemplateMenu, settingsMenu };
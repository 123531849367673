import React, { useState } from "react";
import Header from "../../layouts/Header";
import { Nav, Tab } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import Clients from "./Client/manage";
import ClientMaster from "./ClientMaster/client-master";
import UserSetup from "./Users/manage";
import Subscription from "./Subscription/manage";
import SubscriptionMaster from "./SubscriptionMaster/subscription-master";
import AccessLevel from "./AccessLevel/manage";

export default function SystemSettings() {
    const [isSidebarShow, setSidebarShow] = useState(false);

    return (
        <React.Fragment>
            <Header />
            <Tab.Container id="left-tabs-example" defaultActiveKey="client_tab">
                <div className={"main main-file-manager" + (isSidebarShow ? " show" : "")}>
                    <PerfectScrollbar className="file-sidebar page-in-tabs">
                        <Nav variant="pills">
                            <Nav.Item><Nav.Link eventKey="client_tab"><i className="ri-user-2-line"></i> Client</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="client_master_tab"><i className="ri-user-2-line"></i> Client Master</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="subscription_tab"><i className="ri-mail-volume-line"></i> Subscription</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="subscription_master_tab"><i className="ri-mail-volume-line"></i> Subscription Master</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="users_tab"><i className="ri-group-line"></i> Users</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="access_level_tab"><i className="ri-shield-keyhole-line"></i> Access Level</Nav.Link></Nav.Item>
                        </Nav>
                    </PerfectScrollbar>

                    <PerfectScrollbar className="file-content p-3 p-lg-4">
                        <Link href="" className="menu-file-manager" onClick={() => setSidebarShow(!isSidebarShow)}>
                            <i className="ri-arrow-left-line"></i>
                        </Link>

                        <Tab.Content>
                            {/* CLIENT TAB */}
                            <Tab.Pane eventKey="client_tab"><Clients /></Tab.Pane>
                           
                            {/* CLIENT MASTER TAB */}
                            <Tab.Pane eventKey="client_master_tab"><ClientMaster /></Tab.Pane>

                            {/* SUBSCRIPTION TAB */}
                            <Tab.Pane eventKey="subscription_tab"><Subscription /></Tab.Pane>

                            {/* SUBSCRIPTION MASTER TAB */}
                            <Tab.Pane eventKey="subscription_master_tab"><SubscriptionMaster /></Tab.Pane>

                            {/* USERS TAB */}
                            <Tab.Pane eventKey="users_tab"><UserSetup /></Tab.Pane>

                            {/* ACCESS LEVEL TAB */}
                            <Tab.Pane eventKey="access_level_tab"><AccessLevel /></Tab.Pane>
                        </Tab.Content>
                    </PerfectScrollbar>
                </div>
            </Tab.Container>
        </React.Fragment>
    )
}
import { ADD_FINANCE_TIME_SHEET_STATUS, GET_APP_CLIENTS_List_BY_ID, GET_FINANACE_TIME_SHEET_DETAILS, GET_TIME_SHEET_FINANACE_COUNT, GetFinanceTimeSheetDetailsSuccess, GetTimeSheetFinanceSuccess } from "../actions/financeTimeSheetAction";



const GetTimeCount =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_TIME_SHEET_FINANACE_COUNT) {
      try {
        const getTimeSheetCountFin = await api.financeAPI.GetCount();
        dispatch(GetTimeSheetFinanceSuccess(getTimeSheetCountFin));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
const GetFinanceList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_FINANACE_TIME_SHEET_DETAILS) {
      try {
        const getFinanTimeSheetFina = await api.financeAPI.GetFinanceCientList();
        dispatch(GetFinanceTimeSheetDetailsSuccess(getFinanTimeSheetFina));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };

  const GetClientId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_APP_CLIENTS_List_BY_ID) {
      try {
        const getdata = await api.financeAPI.GetClientById(action.payload);
        dispatch(GetFinanceTimeSheetDetailsSuccess(getdata));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };

  const AddTimeSheetStatu =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_FINANCE_TIME_SHEET_STATUS) {
      try {     
        const getDayWise = await api.financeAPI.AddFinanceTime_Sheet_Status(action.payload);
        const getFinanTimeSheetFina = await api.financeAPI.GetFinanceCientList();
        dispatch(GetFinanceTimeSheetDetailsSuccess(getFinanTimeSheetFina));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  }
  export default [GetTimeCount,
    GetFinanceList,GetClientId ,
    AddTimeSheetStatu
  ];
import { GET_APPROVER_TIME_SHEET_DETAILS_SUCCESS, GET_TIME_SHEET_APPROVER_SUCCESS } from "../actions/approverTimeSheetAction";

  const initialState = {
    coutList: [],
    getApproverTimeSheetDetails:[],

    error: null,
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_TIME_SHEET_APPROVER_SUCCESS:
        return { ...state, coutList: action.payload.result, error: null };
      case GET_APPROVER_TIME_SHEET_DETAILS_SUCCESS:
        return { ...state, getApproverTimeSheetDetails: action.payload.result, error: null };
      default:
        return state;
    }
  };
  
  export default reducer;
  
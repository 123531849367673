import React from "react";
import { Card, Col, Image, Row, Table } from "react-bootstrap";

export default function NewTemplate() {
    return (
        <React.Fragment>
            <Row>
                <Col md={4}>
                    <Card className="card-one">
                        <Card.Body>

                        </Card.Body>
                    </Card>
                </Col>
                <Col md={8}>
                    <Card className="card-one">
                        <Card.Body>
                            <div className="template-main-content">
                                <Row className="mb-5">
                                    <Col>
                                        <div>
                                            <Image src="https://placehold.co/200x80/png" alt="logo" />
                                        </div>
                                    </Col>
                                    <Col>
                                        <h2 className="fs-16 fw-bold mb-2">INVOICE</h2>
                                        <ul>
                                            <li className="d-flex justify-content-between">
                                                <span className="flex-fill fw-semibold">Invoice #:</span>
                                                <span className="flex-fill text-right">1234</span>
                                            </li>

                                            <li className="d-flex">
                                                <span className="flex-fill fw-semibold">Invoice Data:</span>
                                                <span className="flex-fill text-right">MMM.DDD.YEAR</span>
                                            </li>

                                            <li className="d-flex">
                                                <span className="flex-fill fw-semibold">Send Data:</span>
                                                <span className="flex-fill text-right">MMM.DDD.YEAR</span>
                                            </li>
                                            <li className="d-flex">
                                                <span className="flex-fill fw-semibold">Payment Due Data:</span>
                                                <span className="flex-fill text-right">MMM.DDD.YEAR</span>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>

                                <Row className="mb-5">
                                    <Col>
                                    <div>
                                        <h3 className="fs-14 mb-1">
                                            Innoval Global Solutions 4222 <br />
                                            Trinity Mils, Suite 260 Dallax TX 75287
                                        </h3>

                                        <h3 className="fs-14">
                                            Phone: 214-873-6610
                                        </h3>
                                    </div>
                                    </Col>
                                    <Col>
                                    <div className="d-flex align-items-baseline gap-2">
                                        <div className="fs-14 fw-bold">Bill To:</div>
                                        <div>
                                            <h3 className="fs-14 mb-1">XYZ</h3>
                                            <h3 className="fs-14 mb-1">Address</h3>
                                        </div>
                                    </div>
                                    </Col>
                                </Row>

                                <Table className="mb-0" responsive>
                                    <thead>
                                        <tr>
                                            <th>Stream Description</th>
                                            <th>Service Period</th>
                                            <th>Collection</th>
                                            <th>Commission %</th>
                                            <th>Fee</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Minimum Threhold</td>
                                            <td>MMYY-MMYY</td>
                                            <td>0</td>
                                            <td>%0.00</td>
                                            <td>$0.00</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th className="text-right" colSpan={5}>
                                                <span className="me-2 fs-16 fw-semibold text-dark">Total:</span>
                                                <span className="fs-16 fw-semibold text-dark">$0.00</span>
                                            </th>
                                        </tr>
                                    </tfoot>
                                </Table>

                                <h3 className="text-center fs-18 fst-italic fw-bold mt-4">Thank You For Your Business!</h3>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}
import React from "react";
import { Button, Offcanvas, Form } from "react-bootstrap";
import Select from "react-select";


export default function AddNewUserGroup(props) {
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-30">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Add New User Group</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div>
                        <Form.Label>Group Name</Form.Label>
                        <Select isSearchable={true} />
                    </div>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button type="submit" variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Add</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
};
export const GET_ALL_COMMONLIST = "GET_ALL_COMMONLIST";
export const GET_ALL_COMMONLIST_SUCCESS = "GET_ALL_COMMONLIST_SUCCESS";
export const GET_ALL_CONTRACTLIST = "GET_ALL_CONTRACTLIST";
export const GET_ALL_CONTRACTLIST_SUCCESS = "GET_ALL_CONTRACTLIST_SUCCESS";

export const GetCommonList = {
  type: GET_ALL_COMMONLIST
};

export const getCommonListSuccess = (data) => ({
  type: GET_ALL_COMMONLIST_SUCCESS,
  payload: data
});
export const GetContractTypeList = {
  type: GET_ALL_CONTRACTLIST
};

export const getContractListSuccess = (data) => ({
  type: GET_ALL_CONTRACTLIST_SUCCESS,
  payload: data
});

import React, { useState } from "react";
import paidInvoiceData from "../../Json/Invoice/paid-invoice.json";
import { Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";

export default function PaidInvoice() {
    const columns = [
        {
            name: "Client",
            selector: (row) => row?.client,
            sortable: true,
        },
        {
            name: "Sub",
            selector: (row) => row?.sub,
            sortable: true,
        },
        {
            name: "Month",
            selector: (row) => row?.month,
            sortable: true,
        },
        {
            name: "Invoice Type",
            selector: (row) => row?.invoice_type,
            sortable: true,
        },
        {
            name: "Invoice Amount Code",
            selector: (row) => row?.invoice_amount,
            sortable: true,
        },
        {
            name: "Submission Date",
            selector: (row) => row?.invoice_amount,
            sortable: true,
        }
    ];

     // SEARCH FILTER TABLE DATA
     const [searchTerm, setSearchTerm] = useState('');
     const filteredPaidInvoice = paidInvoiceData?.filter((item) =>
         Object.values(item).some((value) =>
             value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
         )
     );
 

    return (
        <React.Fragment>
            <div className="d-md-flex align-items-center justify-content-between mb-3">
                <h3 className="fs-14 mb-1">Paid Invoice</h3>
            </div>

            <Card className="card-one">
                <Card.Header className="align-items-center justify-content-between">
                    <h4 className="main-title fs-14 mb-0">List of Paid Invoice</h4>
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-300">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <DataTable
                        columns={columns}
                        data={filteredPaidInvoice}
                        fixedHeader
                        search={true}
                        highlightOnHover
                        pagination
                    ></DataTable>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}

import { ADD_APPROVER_TIMESHEET_STATUS, GET_APP_CLIENTS_List_BY_ID, GET_APPROVER_TIME_SHEET_DETAILS, GET_TIME_SHEET_APPROVER_COUNT, GetApproverTimeSheetDetailsSuccess, GetTimeSheetApproverSuccess } from "../actions/approverTimeSheetAction.js";
import { ADD_TIMESHEET_STATUS } from "../actions/timeSheetAction.js";


const GetTimeCount =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_TIME_SHEET_APPROVER_COUNT) {
      try {
        const getTimeSheetCountApp = await api.approverAPI.GetCount();
        dispatch(GetTimeSheetApproverSuccess(getTimeSheetCountApp));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
const GetApproverList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_APPROVER_TIME_SHEET_DETAILS) {
      try {
        const getApprTimeSheetApp = await api.approverAPI.GetApproverCientList();
        dispatch(GetApproverTimeSheetDetailsSuccess(getApprTimeSheetApp));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };

  const GetClientId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_APP_CLIENTS_List_BY_ID) {
      try {
        const getdata = await api.approverAPI.GetClientById(action.payload);
        dispatch(GetApproverTimeSheetDetailsSuccess(getdata));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
  const AddTimeSheetStatu =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_APPROVER_TIMESHEET_STATUS) {
      try {     
        const getDayWise = await api.approverAPI.AddAppRoverTime_Sheet_Status(action.payload);
        const getApprTimeSheetApp = await api.approverAPI.GetApproverCientList();
        dispatch(GetApproverTimeSheetDetailsSuccess(getApprTimeSheetApp));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         


  export default [GetTimeCount,
    GetApproverList,GetClientId,AddTimeSheetStatu
  ];
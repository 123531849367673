import { GET_FINANACE_TIME_SHEET_DETAILS_SUCCESS, GET_TIME_SHEET_FINANACE_SUCCESS } from "../actions/financeTimeSheetAction";

  const initialState = {
    coutList: [],
    getFinanaceTimeSheetDetails:[],

    error: null,
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_TIME_SHEET_FINANACE_SUCCESS:
        return { ...state, coutList: action.payload.result, error: null };
      case GET_FINANACE_TIME_SHEET_DETAILS_SUCCESS:
        return { ...state, getFinanaceTimeSheetDetails: action.payload.result, error: null };
      default:
        return state;
    }
  };
  
  export default reducer;
  
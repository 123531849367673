import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import useAuth from "../hooks/useAuth";

export default function Main() {
  const { setAuth, auth } = useAuth();
  

  const offsets = ["/apps/file-manager", "/apps/email", "/apps/calendar", "/reviewer/manage", "/invoice/manage", "/setting/manage", "/manage/client/details"];
  const { pathname } = useLocation();
  const bc = document.body.classList;

  // set sidebar to offset
  (offsets.includes(pathname)) ? bc.add("sidebar-offset") : bc.remove("sidebar-offset");

  // auto close sidebar when switching pages in mobile
  bc.remove("sidebar-show");
 
  // scroll to top when switching pages
  window.scrollTo(0, 0);
  
  return (
    <React.Fragment>
      <Sidebar role={auth?.roles[0]} /> {/* Pass the role as a prop */}
      <Outlet />
    </React.Fragment>
  )
}
